/**
 * Download
 */
.inner-container--download
{
    @include fp(width, 470);
    &>h1
    {
        width: 100%;
    }
    &>p
    {
        width: 100%;
    }
    .downloads
    {
        display: flex;
        width: 100%;
        &>div
        {
            flex: 1 1 33%;
            @include fp(padding-right, 20); 
            
            &:hover
            {
                &.grey
                {
                    img
                    {
                        filter: saturate(1);
                    }
                }
            }
            img
            {
                display: block;
                margin-left: auto;
                margin-right: auto;
                @include fp(margin-bottom, 20);
                @include fp(margin-top, 40);
                @include fp(height, 80);
            }
            &.grey
            {
                img
                {
                    filter: saturate(0);
                    transition: filter 300ms ease;
                }
            }
        }
        .download-button
        {
            width: 100%;
        }
    }
}