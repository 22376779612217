/**
 * Login file, please import in other stylesheets
 */

.login-container
{
    position: relative;
    width: 100%;
    @include fp(max-width, 400);
    @include media($mobile, max-width, 100%);
    margin: 0 auto;
    @include media($mobile, margin, 0);
    min-height: 100vh;
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    border-left: 1px solid $lineColor;
    border-right: 1px solid $lineColor;
    @include media($mobile, border-left, 0px, solid, $lineColor);
    @include media($mobile, border-right, 0px, solid, $lineColor);
    @include fp(padding, 0, 35);
    @include media($mobile, padding, 0);
    //ie only for valign
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        height: 400px;
    }

    .error-message
    {
        width: 100%;
    }
    
    img.plus
    {
        position: absolute;
        right: 0;
        @include fp(top, 100);
        @include fp(width, 25);
        @include fp(height, 25);
        transform: translateX(50%);
        @include media($mobile, display, none);
    }
    
    img.cross
    {
        position: absolute;
        left: 0;
        @include fp(bottom, 100);
        @include fp(width, 25);
        @include fp(height, 25);
        transform: translateX(-50%);
        @include media($mobile, display, none);
    }

    h1
    {
        display: block;
        @include fp(margin-bottom, 50);
    }

    p.message
    {
        width: 100%;
        @include fp(margin-top, -30);
        @include fp(margin-bottom, 40);
        &:last-of-type
        {
            @include fp(margin-bottom, 20);
        }
        &.message--error
        {
            color: red;
        }
        &.message--success
        {
            color: green;
        }
    }

    form
    {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        input[type="email"],
        input[type="password"],
        input[type="text"]
        {
            outline: none;
            width: 48%;
            @include media($mobile, width, 100%);
            border: 0;
            border-bottom: 1px solid #D8D8D8;
            font-family: $bodyFont;
            font-weight: 300;
            @include fp(font-size, 12);
            @include media($laptop, font-size, 14px);
            @include fp(line-height, 14);
            @include media($laptop, line-height, 16px);
            color: #4F5362;
            background-color: transparent;
            &.full
            {
                width: 100%;
            }
        }

        input[type="text"]
        {
            width: 100%;
            margin-right: 0 !important;
        }

        input:not([type="button"]):not([type="submit"]):first-of-type
        {
            margin-right: 4%;
            @include media($mobile, margin-right, 0);
            @include media($mobile, margin-bottom, 20px);
        }
        
        input[type="button"],
        input[type="submit"]
        {
            @include fp(width, 27);
            @include media($laptop, width, 32px);
            @include fp(height, 17);
            @include media($laptop, height, 20px);
            background-color: $mainColor;
            border: 0;
            outline: 0;
            cursor: pointer;
        }
        .submit
        {
            @include fp(margin-top, 20);
            position: relative;
            margin-left: auto;
            &:hover
            {
                &::after
                {
                    transform: translateX(25%) rotate(-45deg);
                }
            }
            &::after
            {
                content: '';
                @include fp(width, 4);
                @include media($laptop, width, 5px);
                @include fp(height, 4);
                @include media($laptop, height, 5px);
                border-style: solid;
                border-color: white;
                @include fp(border-width, 0, 1, 1, 0);
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                transform: translateX(-25%) rotate(-45deg);
                transition: transform 200ms ease;
            }
        }
    }

    a
    {
        @include fp(font-size, 8);
        @include media($laptop, font-size, 12px);
        @include fp(margin-top, 20);
        font-weight: 700;
        color: $secondColor;
        transition: color 200ms ease;
        display: block;
        width: 100%;
        &:hover
        {
            color: black;
        }
    }
}

