/**
 * Menu
 */

.menu
{
    display: flex;
    @include media($mobile, display, none);
    position: fixed;
    @include fp(right, 50);
    @include fp(top, 60);
    z-index: 10;

    @include media($mobile, width, 100%);
    @include media($mobile, right, 0);
    @include media($mobile, left, 0);
    @include media($mobile, top, 80px);
    @include media($mobile, padding, 10px, 0px);
    @include media($mobile, background-color, white);
    transition: top 300ms ease;
    &.sticky
    {
        @include fp(top, 23);
        @include media($mobile, top, 80px);
    }
    .menu-item
    {
        font-family: $headingFont;
        color: black;
        @include fp(font-size, 12);
        @include media($mobile, display, block);
        @include media($mobile, font-size, 20px);
        @include fp(line-height, 14.5);
        @include media($mobile, line-height, 30px);
        @include fp(margin-left, 20);
        @include media($mobile, margin-top, 5px);
        transform: translate3d(0, 0, 0);
        transition: transform 50ms ease;
        &:not(.active):hover
        {
            transform: translate3d(0, 0, 0) skewX(-10deg);
        }
        &.active
        {
            font-weight: 900;
        }
        &.menu-item--blue
        {
            color: $mainColor;
        }
    }
}

.hamburger
{
    display: none;
    @include media($mobile, display, flex);
    height: 80px;
    align-items: center;
    padding: 0 35px;
    justify-content: flex-end;
    position: fixed;
    width: 100%;
    z-index: 10;
    img
    {
        width: 35px;
        height: 35px;
        transition: transform 300ms ease;
    }
    &.open
    {
        img
        {
            transform: rotate(-135deg);
        }
    }
}

.sticky-header
{
    position: fixed;
    display: block;
    width: 100%;
    @include fp(height, 60);
    @include media($mobile, height, 60px);
    border-bottom: 1px solid $secondColor;
    background-color: white;
    z-index: 8;
    @include fp(top, -60);
    @include media($mobile, top, -60px);
    left: 0;
    right: 0;
    transition: top 300ms ease;
    &.sticky
    {
        top: 0;
        @include media($mobile, height, 80px);
    }
}