/**
 * CSS Grid
 *
 * @author miWebb <info@miwebb.com>
 * @version 1.2.0
 */

$breakpoints: (
	('laptop', '1440px'),
	('tablet', '1024px'),
	('mobile', '640px'),
);

$numberOfRows: 12;

[class^='row'], [class^='col'], [class*=' row'], [class*=' col']
{
	box-sizing: border-box;
}

.row
{
	// difine row
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex: 1 1 auto;
	-webkit-flex-direction: row;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: flex-start;

	//row with only padding between columns
	// WARNING: does not work if row is also a column!
	&.row--no-outer-gutter
	{
		margin-left: -15px;
		margin-right: -15px;
	}

	// difine row types
	&.row--same-size
	{
		align-items: stretch;
	}

	&.row--reverse
	{
		-webkit-flex-direction: row-reverse;
		flex-direction: row-reverse;
	}

	// difine row alignments
	&.row--top
	{
		align-items: flex-start;
	}

	&.row--middle
	{
		align-items: center;
	}

	&.row--bottom
	{
		align-items: flex-end;
	}

	&.row--left
	{
		justify-content: flex-start;
	}

	&.row--center
	{
		justify-content: center;
	}

	&.row--right
	{
		justify-content: flex-end;
	}

	&.row--around
	{
		justify-content: space-around;
	}

	&.row--between
	{
		justify-content: space-between;
	}

	&.row--evenly
	{
		justify-content: space-evenly;
	}

	// set the column padding
	&:not(.row--no-gutter) > [class^='col'],
	&:not(.row--no-gutter) > [class*=' col']
	{
		padding-left: 15px;
		padding-right: 15px;
	}

	// set auto size column
	& > .col
	{
		flex: 1 0 0%;
	}

	@for $i from 1 through $numberOfRows
	{
		// set specific size column
		& > .col-#{$i}
		{
			flex: 0 0 #{$i*100%/$numberOfRows};
			max-width: #{$i*100%/$numberOfRows};
		}

		// set column offset
		& > .col-offset-#{$i}
		{
			margin-left: #{$i*100%/$numberOfRows};
		}

		&.row--reverse > .col-offset-#{$i}
		{
			margin-right: #{$i*100%/$numberOfRows};
			margin-left: inherit;
		}

		// set column order
		& > .col-order-#{$i}
		{
			order: #{$i};
		}
	}

	// set column offset 0
	& > .col-offset-0
	{
		margin-left: 0px;
	}

	&.row--reverse > .col-offset-0
	{
		margin-right: 0px;
		margin-left: inherit;
	}

	// set column order 0
	& > .col-order-0
	{
			order: 0;
	}

	// special screen size columns
	@each $breakpoints-attribute-name, $breakpoints-attribute in $breakpoints
	{
		@media screen and (max-width: $breakpoints-attribute)
		{
			& > .col-#{$breakpoints-attribute-name}
			{
				flex: 1 0 0;
			}

			@for $i from 1 through $numberOfRows
			{
				& > .col-#{$breakpoints-attribute-name}-#{$i}
				{
					flex: 0 0 #{$i*100%/$numberOfRows};
					max-width: #{$i*100%/$numberOfRows};
				}

				& > .col-#{$breakpoints-attribute-name}-offset-#{$i}
				{
					margin-left: #{$i*100%/$numberOfRows};
				}

				&.row--reverse > .col-#{$breakpoints-attribute-name}-offset-#{$i}
				{
					margin-right: #{$i*100%/$numberOfRows};
					margin-left: inherit;
				}

				& > .col-#{$breakpoints-attribute-name}-order-#{$i}
				{
					order: #{$i};
				}
			}

			// set column offset 0
			& > .col-#{$breakpoints-attribute-name}-offset-0
			{
				margin-left: 0px;
			}

			&.row--reverse > .col-#{$breakpoints-attribute-name}-offset-0
			{
				margin-right: 0px;
				margin-left: inherit;
			}

			// set column order 0
			& > .col-#{$breakpoints-attribute-name}-order-0
			{
				order: 0;
			}
		}
	}
}