/**
 * Overview
 */
.inner-container--overview
{
    //ie only for valign
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        height: 400px;
    }
    p
    {
        font-family: $headingFont;
        @include fp(font-size, 45);
        @include fp(line-height, 54);
        color: $secondColor;
        @include fp(padding-bottom, 80);
        span[data-fy-field="first_name"]
        {
            display: inline-block;
            overflow: hidden;
            @include fp(max-width, 300);
            vertical-align: bottom;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        span.highlight
        {
            position: relative;
            display: inline-block;
            background-color: black;
            color: white;
            @include fp(padding, 0, 5, 5, 5);
            @include fp(margin, 4, 0, 0, 0);
            a
            {
                color: white;
            }
            .popup
            {
                @include fp(width, 150);
                text-align: center;
            }
        }
    }
}

.line
{
    display: block;
    @include media($tablet, display, none);
    height: 100%;
    width: 1px;
    background-color: $lineColor;
    position: absolute;
    @include fp(left, 400);
    z-index: -1;
}

.overview
{
   img.plus
   {
       position: absolute;
       @include media($tablet, display, none);
       @include fp(top, 100);
       @include fp(left, 400);
       @include fp(width, 25);
       @include fp(height, 25);
       transform: translateX(-50%);
       @include media($mobile, display, none);
   }

   img.cross
   {
       position: absolute;
       @include media($tablet, display, none);
       @include fp(left, 400);
       @include fp(bottom, 100);
       @include fp(width, 25);
       @include fp(height, 25);
       transform: translateX(-50%);
       @include media($mobile, display, none);
   }
}