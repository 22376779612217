/**
 * Global
 */
/** 
* Variable file, please import in other stylesheets
**/
/**
 * Functions file, please import in other stylesheets
 **/
.inner-container--settings form > label input:not([type="checkbox"]):not([type="submit"]):not([type="radio"]),
.inner-container--settings form > label select {
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none; }

/**
 * Fonts file, please import in other stylesheets
 **/
@font-face {
  font-family: DINPro;
  font-style: normal;
  font-weight: 900;
  src: url("../webfonts/DINPro-Black.woff2") format("woff2"), url("../webfonts/DINPro-Black.woff") format("woff"); }

@font-face {
  font-family: DINPro;
  font-style: normal;
  font-weight: 700;
  src: url("../webfonts/DINPro-Bold.woff2") format("woff2"), url("../webfonts/DINPro-Bold.woff") format("woff"); }

@font-face {
  font-family: DINPro;
  font-style: normal;
  font-weight: 400;
  src: url("../webfonts/DINPro-Regular.woff2") format("woff2"), url("../webfonts/DINPro-Regular.woff") format("woff"); }

@font-face {
  font-family: DINPro;
  font-style: normal;
  font-weight: 300;
  src: url("../webfonts/DINPro-Light.woff2") format("woff2"), url("../webfonts/DINPro-Light.woff") format("woff"); }

@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 700;
  src: url("../webfonts/Rajdhani-Bold.woff2") format("woff2"), url("../webfonts/Rajdhani-Bold.woff") format("woff"); }

@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 600;
  src: url("../webfonts/Rajdhani-SemiBold.woff2") format("woff2"), url("../webfonts/Rajdhani-SemiBold.woff") format("woff"); }

@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  src: url("../webfonts/Rajdhani-Medium.woff2") format("woff2"), url("../webfonts/Rajdhani-Medium.woff") format("woff"); }

@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 400;
  src: url("../webfonts/Rajdhani-Regular.woff2") format("woff2"), url("../webfonts/Rajdhani-Regular.woff") format("woff"); }

@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 300;
  src: url("../webfonts/Rajdhani-Light.woff2") format("woff2"), url("../webfonts/Rajdhani-Light.woff") format("woff"); }

/**
 * CSS Grid
 *
 * @author miWebb <info@miwebb.com>
 * @version 1.2.0
 */
[class^='row'], [class^='col'], [class*=' row'], [class*=' col'] {
  box-sizing: border-box; }

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex: 1 1 auto;
  -webkit-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start; }
  .row.row--no-outer-gutter {
    margin-left: -15px;
    margin-right: -15px; }
  .row.row--same-size {
    align-items: stretch; }
  .row.row--reverse {
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse; }
  .row.row--top {
    align-items: flex-start; }
  .row.row--middle {
    align-items: center; }
  .row.row--bottom {
    align-items: flex-end; }
  .row.row--left {
    justify-content: flex-start; }
  .row.row--center {
    justify-content: center; }
  .row.row--right {
    justify-content: flex-end; }
  .row.row--around {
    justify-content: space-around; }
  .row.row--between {
    justify-content: space-between; }
  .row.row--evenly {
    justify-content: space-evenly; }
  .row:not(.row--no-gutter) > [class^='col'],
  .row:not(.row--no-gutter) > [class*=' col'] {
    padding-left: 15px;
    padding-right: 15px; }
  .row > .col {
    flex: 1 0 0%; }
  .row > .col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .row > .col-offset-1 {
    margin-left: 8.33333%; }
  .row.row--reverse > .col-offset-1 {
    margin-right: 8.33333%;
    margin-left: inherit; }
  .row > .col-order-1 {
    order: 1; }
  .row > .col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .row > .col-offset-2 {
    margin-left: 16.66667%; }
  .row.row--reverse > .col-offset-2 {
    margin-right: 16.66667%;
    margin-left: inherit; }
  .row > .col-order-2 {
    order: 2; }
  .row > .col-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .row > .col-offset-3 {
    margin-left: 25%; }
  .row.row--reverse > .col-offset-3 {
    margin-right: 25%;
    margin-left: inherit; }
  .row > .col-order-3 {
    order: 3; }
  .row > .col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row > .col-offset-4 {
    margin-left: 33.33333%; }
  .row.row--reverse > .col-offset-4 {
    margin-right: 33.33333%;
    margin-left: inherit; }
  .row > .col-order-4 {
    order: 4; }
  .row > .col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .row > .col-offset-5 {
    margin-left: 41.66667%; }
  .row.row--reverse > .col-offset-5 {
    margin-right: 41.66667%;
    margin-left: inherit; }
  .row > .col-order-5 {
    order: 5; }
  .row > .col-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .row > .col-offset-6 {
    margin-left: 50%; }
  .row.row--reverse > .col-offset-6 {
    margin-right: 50%;
    margin-left: inherit; }
  .row > .col-order-6 {
    order: 6; }
  .row > .col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .row > .col-offset-7 {
    margin-left: 58.33333%; }
  .row.row--reverse > .col-offset-7 {
    margin-right: 58.33333%;
    margin-left: inherit; }
  .row > .col-order-7 {
    order: 7; }
  .row > .col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .row > .col-offset-8 {
    margin-left: 66.66667%; }
  .row.row--reverse > .col-offset-8 {
    margin-right: 66.66667%;
    margin-left: inherit; }
  .row > .col-order-8 {
    order: 8; }
  .row > .col-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .row > .col-offset-9 {
    margin-left: 75%; }
  .row.row--reverse > .col-offset-9 {
    margin-right: 75%;
    margin-left: inherit; }
  .row > .col-order-9 {
    order: 9; }
  .row > .col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .row > .col-offset-10 {
    margin-left: 83.33333%; }
  .row.row--reverse > .col-offset-10 {
    margin-right: 83.33333%;
    margin-left: inherit; }
  .row > .col-order-10 {
    order: 10; }
  .row > .col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .row > .col-offset-11 {
    margin-left: 91.66667%; }
  .row.row--reverse > .col-offset-11 {
    margin-right: 91.66667%;
    margin-left: inherit; }
  .row > .col-order-11 {
    order: 11; }
  .row > .col-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .row > .col-offset-12 {
    margin-left: 100%; }
  .row.row--reverse > .col-offset-12 {
    margin-right: 100%;
    margin-left: inherit; }
  .row > .col-order-12 {
    order: 12; }
  .row > .col-offset-0 {
    margin-left: 0px; }
  .row.row--reverse > .col-offset-0 {
    margin-right: 0px;
    margin-left: inherit; }
  .row > .col-order-0 {
    order: 0; }
  @media screen and (max-width: 1440px) {
    .row > .col-laptop {
      flex: 1 0 0; }
    .row > .col-laptop-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    .row > .col-laptop-offset-1 {
      margin-left: 8.33333%; }
    .row.row--reverse > .col-laptop-offset-1 {
      margin-right: 8.33333%;
      margin-left: inherit; }
    .row > .col-laptop-order-1 {
      order: 1; }
    .row > .col-laptop-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .row > .col-laptop-offset-2 {
      margin-left: 16.66667%; }
    .row.row--reverse > .col-laptop-offset-2 {
      margin-right: 16.66667%;
      margin-left: inherit; }
    .row > .col-laptop-order-2 {
      order: 2; }
    .row > .col-laptop-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    .row > .col-laptop-offset-3 {
      margin-left: 25%; }
    .row.row--reverse > .col-laptop-offset-3 {
      margin-right: 25%;
      margin-left: inherit; }
    .row > .col-laptop-order-3 {
      order: 3; }
    .row > .col-laptop-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .row > .col-laptop-offset-4 {
      margin-left: 33.33333%; }
    .row.row--reverse > .col-laptop-offset-4 {
      margin-right: 33.33333%;
      margin-left: inherit; }
    .row > .col-laptop-order-4 {
      order: 4; }
    .row > .col-laptop-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .row > .col-laptop-offset-5 {
      margin-left: 41.66667%; }
    .row.row--reverse > .col-laptop-offset-5 {
      margin-right: 41.66667%;
      margin-left: inherit; }
    .row > .col-laptop-order-5 {
      order: 5; }
    .row > .col-laptop-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    .row > .col-laptop-offset-6 {
      margin-left: 50%; }
    .row.row--reverse > .col-laptop-offset-6 {
      margin-right: 50%;
      margin-left: inherit; }
    .row > .col-laptop-order-6 {
      order: 6; }
    .row > .col-laptop-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .row > .col-laptop-offset-7 {
      margin-left: 58.33333%; }
    .row.row--reverse > .col-laptop-offset-7 {
      margin-right: 58.33333%;
      margin-left: inherit; }
    .row > .col-laptop-order-7 {
      order: 7; }
    .row > .col-laptop-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .row > .col-laptop-offset-8 {
      margin-left: 66.66667%; }
    .row.row--reverse > .col-laptop-offset-8 {
      margin-right: 66.66667%;
      margin-left: inherit; }
    .row > .col-laptop-order-8 {
      order: 8; }
    .row > .col-laptop-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    .row > .col-laptop-offset-9 {
      margin-left: 75%; }
    .row.row--reverse > .col-laptop-offset-9 {
      margin-right: 75%;
      margin-left: inherit; }
    .row > .col-laptop-order-9 {
      order: 9; }
    .row > .col-laptop-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    .row > .col-laptop-offset-10 {
      margin-left: 83.33333%; }
    .row.row--reverse > .col-laptop-offset-10 {
      margin-right: 83.33333%;
      margin-left: inherit; }
    .row > .col-laptop-order-10 {
      order: 10; }
    .row > .col-laptop-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    .row > .col-laptop-offset-11 {
      margin-left: 91.66667%; }
    .row.row--reverse > .col-laptop-offset-11 {
      margin-right: 91.66667%;
      margin-left: inherit; }
    .row > .col-laptop-order-11 {
      order: 11; }
    .row > .col-laptop-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    .row > .col-laptop-offset-12 {
      margin-left: 100%; }
    .row.row--reverse > .col-laptop-offset-12 {
      margin-right: 100%;
      margin-left: inherit; }
    .row > .col-laptop-order-12 {
      order: 12; }
    .row > .col-laptop-offset-0 {
      margin-left: 0px; }
    .row.row--reverse > .col-laptop-offset-0 {
      margin-right: 0px;
      margin-left: inherit; }
    .row > .col-laptop-order-0 {
      order: 0; } }
  @media screen and (max-width: 1024px) {
    .row > .col-tablet {
      flex: 1 0 0; }
    .row > .col-tablet-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    .row > .col-tablet-offset-1 {
      margin-left: 8.33333%; }
    .row.row--reverse > .col-tablet-offset-1 {
      margin-right: 8.33333%;
      margin-left: inherit; }
    .row > .col-tablet-order-1 {
      order: 1; }
    .row > .col-tablet-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .row > .col-tablet-offset-2 {
      margin-left: 16.66667%; }
    .row.row--reverse > .col-tablet-offset-2 {
      margin-right: 16.66667%;
      margin-left: inherit; }
    .row > .col-tablet-order-2 {
      order: 2; }
    .row > .col-tablet-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    .row > .col-tablet-offset-3 {
      margin-left: 25%; }
    .row.row--reverse > .col-tablet-offset-3 {
      margin-right: 25%;
      margin-left: inherit; }
    .row > .col-tablet-order-3 {
      order: 3; }
    .row > .col-tablet-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .row > .col-tablet-offset-4 {
      margin-left: 33.33333%; }
    .row.row--reverse > .col-tablet-offset-4 {
      margin-right: 33.33333%;
      margin-left: inherit; }
    .row > .col-tablet-order-4 {
      order: 4; }
    .row > .col-tablet-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .row > .col-tablet-offset-5 {
      margin-left: 41.66667%; }
    .row.row--reverse > .col-tablet-offset-5 {
      margin-right: 41.66667%;
      margin-left: inherit; }
    .row > .col-tablet-order-5 {
      order: 5; }
    .row > .col-tablet-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    .row > .col-tablet-offset-6 {
      margin-left: 50%; }
    .row.row--reverse > .col-tablet-offset-6 {
      margin-right: 50%;
      margin-left: inherit; }
    .row > .col-tablet-order-6 {
      order: 6; }
    .row > .col-tablet-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .row > .col-tablet-offset-7 {
      margin-left: 58.33333%; }
    .row.row--reverse > .col-tablet-offset-7 {
      margin-right: 58.33333%;
      margin-left: inherit; }
    .row > .col-tablet-order-7 {
      order: 7; }
    .row > .col-tablet-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .row > .col-tablet-offset-8 {
      margin-left: 66.66667%; }
    .row.row--reverse > .col-tablet-offset-8 {
      margin-right: 66.66667%;
      margin-left: inherit; }
    .row > .col-tablet-order-8 {
      order: 8; }
    .row > .col-tablet-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    .row > .col-tablet-offset-9 {
      margin-left: 75%; }
    .row.row--reverse > .col-tablet-offset-9 {
      margin-right: 75%;
      margin-left: inherit; }
    .row > .col-tablet-order-9 {
      order: 9; }
    .row > .col-tablet-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    .row > .col-tablet-offset-10 {
      margin-left: 83.33333%; }
    .row.row--reverse > .col-tablet-offset-10 {
      margin-right: 83.33333%;
      margin-left: inherit; }
    .row > .col-tablet-order-10 {
      order: 10; }
    .row > .col-tablet-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    .row > .col-tablet-offset-11 {
      margin-left: 91.66667%; }
    .row.row--reverse > .col-tablet-offset-11 {
      margin-right: 91.66667%;
      margin-left: inherit; }
    .row > .col-tablet-order-11 {
      order: 11; }
    .row > .col-tablet-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    .row > .col-tablet-offset-12 {
      margin-left: 100%; }
    .row.row--reverse > .col-tablet-offset-12 {
      margin-right: 100%;
      margin-left: inherit; }
    .row > .col-tablet-order-12 {
      order: 12; }
    .row > .col-tablet-offset-0 {
      margin-left: 0px; }
    .row.row--reverse > .col-tablet-offset-0 {
      margin-right: 0px;
      margin-left: inherit; }
    .row > .col-tablet-order-0 {
      order: 0; } }
  @media screen and (max-width: 640px) {
    .row > .col-mobile {
      flex: 1 0 0; }
    .row > .col-mobile-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    .row > .col-mobile-offset-1 {
      margin-left: 8.33333%; }
    .row.row--reverse > .col-mobile-offset-1 {
      margin-right: 8.33333%;
      margin-left: inherit; }
    .row > .col-mobile-order-1 {
      order: 1; }
    .row > .col-mobile-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .row > .col-mobile-offset-2 {
      margin-left: 16.66667%; }
    .row.row--reverse > .col-mobile-offset-2 {
      margin-right: 16.66667%;
      margin-left: inherit; }
    .row > .col-mobile-order-2 {
      order: 2; }
    .row > .col-mobile-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    .row > .col-mobile-offset-3 {
      margin-left: 25%; }
    .row.row--reverse > .col-mobile-offset-3 {
      margin-right: 25%;
      margin-left: inherit; }
    .row > .col-mobile-order-3 {
      order: 3; }
    .row > .col-mobile-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .row > .col-mobile-offset-4 {
      margin-left: 33.33333%; }
    .row.row--reverse > .col-mobile-offset-4 {
      margin-right: 33.33333%;
      margin-left: inherit; }
    .row > .col-mobile-order-4 {
      order: 4; }
    .row > .col-mobile-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .row > .col-mobile-offset-5 {
      margin-left: 41.66667%; }
    .row.row--reverse > .col-mobile-offset-5 {
      margin-right: 41.66667%;
      margin-left: inherit; }
    .row > .col-mobile-order-5 {
      order: 5; }
    .row > .col-mobile-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    .row > .col-mobile-offset-6 {
      margin-left: 50%; }
    .row.row--reverse > .col-mobile-offset-6 {
      margin-right: 50%;
      margin-left: inherit; }
    .row > .col-mobile-order-6 {
      order: 6; }
    .row > .col-mobile-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .row > .col-mobile-offset-7 {
      margin-left: 58.33333%; }
    .row.row--reverse > .col-mobile-offset-7 {
      margin-right: 58.33333%;
      margin-left: inherit; }
    .row > .col-mobile-order-7 {
      order: 7; }
    .row > .col-mobile-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .row > .col-mobile-offset-8 {
      margin-left: 66.66667%; }
    .row.row--reverse > .col-mobile-offset-8 {
      margin-right: 66.66667%;
      margin-left: inherit; }
    .row > .col-mobile-order-8 {
      order: 8; }
    .row > .col-mobile-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    .row > .col-mobile-offset-9 {
      margin-left: 75%; }
    .row.row--reverse > .col-mobile-offset-9 {
      margin-right: 75%;
      margin-left: inherit; }
    .row > .col-mobile-order-9 {
      order: 9; }
    .row > .col-mobile-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    .row > .col-mobile-offset-10 {
      margin-left: 83.33333%; }
    .row.row--reverse > .col-mobile-offset-10 {
      margin-right: 83.33333%;
      margin-left: inherit; }
    .row > .col-mobile-order-10 {
      order: 10; }
    .row > .col-mobile-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    .row > .col-mobile-offset-11 {
      margin-left: 91.66667%; }
    .row.row--reverse > .col-mobile-offset-11 {
      margin-right: 91.66667%;
      margin-left: inherit; }
    .row > .col-mobile-order-11 {
      order: 11; }
    .row > .col-mobile-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    .row > .col-mobile-offset-12 {
      margin-left: 100%; }
    .row.row--reverse > .col-mobile-offset-12 {
      margin-right: 100%;
      margin-left: inherit; }
    .row > .col-mobile-order-12 {
      order: 12; }
    .row > .col-mobile-offset-0 {
      margin-left: 0px; }
    .row.row--reverse > .col-mobile-offset-0 {
      margin-right: 0px;
      margin-left: inherit; }
    .row > .col-mobile-order-0 {
      order: 0; } }

/**
 * Typography file, please import in other stylesheets
 **/
body {
  font-family: "Rajdhani", sans-serif;
  color: #707070; }

a {
  color: #707070;
  text-decoration: none; }

p {
  font-size: 1.33333vw    ;
  letter-spacing: 0.025vw    ;
  line-height: 1.6vw    ;
  font-weight: 300; }
  @media screen and (min-width: 1920px) {
    p {
      font-size: 25.6px    ; } }
  @media screen and (max-width: 1024px) {
    p {
      font-size: 13.65333px    ; } }
  @media screen and (min-width: 1920px) {
    p {
      letter-spacing: 0.48px    ; } }
  @media screen and (max-width: 1024px) {
    p {
      letter-spacing: 0.256px    ; } }
  @media screen and (min-width: 1920px) {
    p {
      line-height: 30.72px    ; } }
  @media screen and (max-width: 1024px) {
    p {
      line-height: 16.384px    ; } }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "DINPro", sans-serif;
  color: #EAEAE8; }

h1 {
  font-size: 4.16667vw    ;
  letter-spacing: 0.03333vw    ;
  line-height: 5vw    ;
  font-weight: 400; }
  @media screen and (min-width: 1920px) {
    h1 {
      font-size: 80px    ; } }
  @media screen and (max-width: 1024px) {
    h1 {
      font-size: 42.66667px    ; } }
  @media screen and (min-width: 1920px) {
    h1 {
      letter-spacing: 0.64px    ; } }
  @media screen and (max-width: 1024px) {
    h1 {
      letter-spacing: 0.34133px    ; } }
  @media screen and (min-width: 1920px) {
    h1 {
      line-height: 96px    ; } }
  @media screen and (max-width: 1024px) {
    h1 {
      line-height: 51.2px    ; } }

h2 {
  font-size: 2.08333vw    ;
  letter-spacing: 0.03333vw    ;
  line-height: 3.33333vw    ;
  font-weight: 400; }
  @media screen and (min-width: 1920px) {
    h2 {
      font-size: 40px    ; } }
  @media screen and (max-width: 1024px) {
    h2 {
      font-size: 21.33333px    ; } }
  @media screen and (min-width: 1920px) {
    h2 {
      letter-spacing: 0.64px    ; } }
  @media screen and (max-width: 1024px) {
    h2 {
      letter-spacing: 0.34133px    ; } }
  @media screen and (min-width: 1920px) {
    h2 {
      line-height: 64px    ; } }
  @media screen and (max-width: 1024px) {
    h2 {
      line-height: 34.13333px    ; } }

h3 {
  font-size: 1.5vw    ;
  letter-spacing: 0.09167vw    ;
  line-height: 1.8vw    ; }
  @media screen and (min-width: 1920px) {
    h3 {
      font-size: 28.8px    ; } }
  @media screen and (max-width: 1024px) {
    h3 {
      font-size: 15.36px    ; } }
  @media screen and (min-width: 1920px) {
    h3 {
      letter-spacing: 1.76px    ; } }
  @media screen and (max-width: 1024px) {
    h3 {
      letter-spacing: 0.93867px    ; } }
  @media screen and (min-width: 1920px) {
    h3 {
      line-height: 34.56px    ; } }
  @media screen and (max-width: 1024px) {
    h3 {
      line-height: 18.432px    ; } }

/**
* Utils file, please import in other stylesheets
**/
/**
 * Elements file, please import in other stylesheets
 **/
.container {
  max-width: 100vw;
  overflow: hidden;
  padding: 0 15px;
  margin: auto;
  min-height: 100vh;
  position: relative; }

.logo {
  display: block;
  position: fixed;
  top: 4.16667vw    ;
  left: 6.91667vw    ;
  width: 5vw    ;
  height: 4.58333vw    ;
  z-index: 11;
  transition: top 300ms ease, width 300ms ease, height 300ms ease; }
  @media screen and (min-width: 1920px) {
    .logo {
      top: 80px    ; } }
  @media screen and (max-width: 1024px) {
    .logo {
      top: 42.66667px    ; } }
  @media screen and (max-width: 640px) {
    .logo {
      top: 15px    ; } }
  @media screen and (min-width: 1920px) {
    .logo {
      left: 132.8px    ; } }
  @media screen and (max-width: 1024px) {
    .logo {
      left: 70.82667px    ; } }
  @media screen and (max-width: 640px) {
    .logo {
      left: 15px    ; } }
  @media screen and (min-width: 1920px) {
    .logo {
      width: 96px    ; } }
  @media screen and (max-width: 1024px) {
    .logo {
      width: 51.2px    ; } }
  @media screen and (min-width: 1920px) {
    .logo {
      height: 88px    ; } }
  @media screen and (max-width: 1024px) {
    .logo {
      height: 46.93333px    ; } }
  .logo img {
    width: 100%;
    height: 100%; }
  .logo.sticky {
    top: 0.83333vw    ;
    width: 3.75vw    ;
    height: 3.33333vw    ; }
    @media screen and (min-width: 1920px) {
      .logo.sticky {
        top: 16px    ; } }
    @media screen and (max-width: 1024px) {
      .logo.sticky {
        top: 8.53333px    ; } }
    @media screen and (max-width: 640px) {
      .logo.sticky {
        top: 15px    ; } }
    @media screen and (min-width: 1920px) {
      .logo.sticky {
        width: 72px    ; } }
    @media screen and (max-width: 1024px) {
      .logo.sticky {
        width: 38.4px    ; } }
    @media screen and (max-width: 640px) {
      .logo.sticky {
        width: 51.2px    ; } }
    @media screen and (min-width: 1920px) {
      .logo.sticky {
        height: 64px    ; } }
    @media screen and (max-width: 1024px) {
      .logo.sticky {
        height: 34.13333px    ; } }
    @media screen and (max-width: 640px) {
      .logo.sticky {
        height: 46.93333px    ; } }

.watermark {
  width: 37.5vw    ;
  height: 37.5vw    ;
  position: absolute;
  bottom: 1.66667vw    ;
  right: 0vw    ;
  transform: translateX(25%);
  z-index: -1; }
  @media screen and (min-width: 1920px) {
    .watermark {
      width: 720px    ; } }
  @media screen and (max-width: 1024px) {
    .watermark {
      width: 384px    ; } }
  @media screen and (min-width: 1920px) {
    .watermark {
      height: 720px    ; } }
  @media screen and (max-width: 1024px) {
    .watermark {
      height: 384px    ; } }
  @media screen and (min-width: 1920px) {
    .watermark {
      bottom: 32px    ; } }
  @media screen and (max-width: 1024px) {
    .watermark {
      bottom: 17.06667px    ; } }
  @media screen and (min-width: 1920px) {
    .watermark {
      right: 0px    ; } }
  @media screen and (max-width: 1024px) {
    .watermark {
      right: 0px    ; } }

.inner-container {
  padding: 8.33333vw 0vw   ;
  width: 50vw    ;
  max-width: 100%;
  margin: auto;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  align-content: center; }
  @media screen and (min-width: 1920px) {
    .inner-container {
      padding: 160px 0px   ; } }
  @media screen and (max-width: 1024px) {
    .inner-container {
      padding: 85.33333px 0px   ; } }
  @media screen and (min-width: 1920px) {
    .inner-container {
      width: 960px    ; } }
  @media screen and (max-width: 1024px) {
    .inner-container {
      width: 512px    ; } }
  .inner-container h1 {
    margin-bottom: 0.83333vw    ; }
    @media screen and (min-width: 1920px) {
      .inner-container h1 {
        margin-bottom: 16px    ; } }
    @media screen and (max-width: 1024px) {
      .inner-container h1 {
        margin-bottom: 8.53333px    ; } }

.popup-trigger {
  position: relative;
  cursor: pointer; }
  .popup-trigger .popup {
    display: none;
    background-color: #2737E6;
    font-family: "DINPro", sans-serif;
    font-size: 1.16667vw    ;
    line-height: 1.4vw    ;
    letter-spacing: 0.11667vw    ;
    bottom: -4.16667vw    ;
    padding: 0.41667vw 1.16667vw 0.58333vw 1.16667vw ;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    position: absolute; }
    @media screen and (min-width: 1920px) {
      .popup-trigger .popup {
        font-size: 22.4px    ; } }
    @media screen and (max-width: 1024px) {
      .popup-trigger .popup {
        font-size: 11.94667px    ; } }
    @media screen and (min-width: 1920px) {
      .popup-trigger .popup {
        line-height: 26.88px    ; } }
    @media screen and (max-width: 1024px) {
      .popup-trigger .popup {
        line-height: 14.336px    ; } }
    @media screen and (min-width: 1920px) {
      .popup-trigger .popup {
        letter-spacing: 2.24px    ; } }
    @media screen and (max-width: 1024px) {
      .popup-trigger .popup {
        letter-spacing: 1.19467px    ; } }
    @media screen and (min-width: 1920px) {
      .popup-trigger .popup {
        bottom: -80px    ; } }
    @media screen and (max-width: 1024px) {
      .popup-trigger .popup {
        bottom: -42.66667px    ; } }
    @media screen and (min-width: 1920px) {
      .popup-trigger .popup {
        padding: 8px 22.4px 11.2px 22.4px ; } }
    @media screen and (max-width: 1024px) {
      .popup-trigger .popup {
        padding: 4.26667px 11.94667px 5.97333px 11.94667px ; } }
    .popup-trigger .popup::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      border-style: solid;
      border-color: transparent transparent #2737E6 transparent;
      border-width: 0.41667vw    ;
      width: 0;
      height: 0;
      transform: translateY(-100%); }
      @media screen and (min-width: 1920px) {
        .popup-trigger .popup::before {
          border-width: 8px    ; } }
      @media screen and (max-width: 1024px) {
        .popup-trigger .popup::before {
          border-width: 4.26667px    ; } }
  .popup-trigger:hover .popup {
    display: block; }

.time-left-container {
  display: block;
  font-family: "DINPro", sans-serif;
  background-color: #1D1D1B;
  position: fixed;
  font-size: 1.16667vw    ;
  line-height: 1.4vw    ;
  padding: 0.41667vw    ;
  right: 2.5vw    ;
  bottom: 1.66667vw    ;
  color: white;
  font-weight: 300;
  z-index: 5; }
  @media screen and (min-width: 1920px) {
    .time-left-container {
      font-size: 22.4px    ; } }
  @media screen and (max-width: 1024px) {
    .time-left-container {
      font-size: 11.94667px    ; } }
  @media screen and (min-width: 1920px) {
    .time-left-container {
      line-height: 26.88px    ; } }
  @media screen and (max-width: 1024px) {
    .time-left-container {
      line-height: 14.336px    ; } }
  @media screen and (min-width: 1920px) {
    .time-left-container {
      padding: 8px    ; } }
  @media screen and (max-width: 1024px) {
    .time-left-container {
      padding: 4.26667px    ; } }
  @media screen and (min-width: 1920px) {
    .time-left-container {
      right: 48px    ; } }
  @media screen and (max-width: 1024px) {
    .time-left-container {
      right: 25.6px    ; } }
  @media screen and (min-width: 1920px) {
    .time-left-container {
      bottom: 32px    ; } }
  @media screen and (max-width: 1024px) {
    .time-left-container {
      bottom: 17.06667px    ; } }
  .time-left-container a {
    color: white; }
  .time-left-container.time-left-container--green {
    background-color: #43bd51; }
  .time-left-container.time-left-container--orange {
    background-color: #FFB55C; }
  .time-left-container.time-left-container--red {
    background-color: #FF4D5A; }

.button {
  display: inline-block;
  appearance: none;
  cursor: pointer;
  border: 0;
  outline: 0;
  background-color: #2737E6;
  font-family: "DINPro", sans-serif;
  color: white;
  font-size: 1.16667vw    ;
  letter-spacing: 0.11667vw    ;
  line-height: 1.4vw    ;
  padding: 0.66667vw 3.16667vw   ;
  transition: background-color 100ms ease;
  white-space: nowrap; }
  @media screen and (min-width: 1920px) {
    .button {
      font-size: 22.4px    ; } }
  @media screen and (max-width: 1024px) {
    .button {
      font-size: 11.94667px    ; } }
  @media screen and (min-width: 1920px) {
    .button {
      letter-spacing: 2.24px    ; } }
  @media screen and (max-width: 1024px) {
    .button {
      letter-spacing: 1.19467px    ; } }
  @media screen and (min-width: 1920px) {
    .button {
      line-height: 26.88px    ; } }
  @media screen and (max-width: 1024px) {
    .button {
      line-height: 14.336px    ; } }
  @media screen and (min-width: 1920px) {
    .button {
      padding: 12.8px 60.8px   ; } }
  @media screen and (max-width: 1024px) {
    .button {
      padding: 6.82667px 32.42667px   ; } }
  .button.button--compact {
    padding: 0.66667vw 1.25vw   ; }
    @media screen and (min-width: 1920px) {
      .button.button--compact {
        padding: 12.8px 24px   ; } }
    @media screen and (max-width: 1024px) {
      .button.button--compact {
        padding: 6.82667px 12.8px   ; } }
  .button:hover {
    background-color: #212a8a; }

.success-message {
  display: none;
  width: 100%;
  padding: 0.83333vw 1.66667vw   ;
  border: 1px solid green;
  color: green;
  border-radius: 5px;
  margin-bottom: 2.66667vw    ; }
  @media screen and (min-width: 1920px) {
    .success-message {
      padding: 16px 32px   ; } }
  @media screen and (max-width: 1024px) {
    .success-message {
      padding: 8.53333px 17.06667px   ; } }
  @media screen and (min-width: 1920px) {
    .success-message {
      margin-bottom: 51.2px    ; } }
  @media screen and (max-width: 1024px) {
    .success-message {
      margin-bottom: 27.30667px    ; } }

.success-message--visible {
  display: block; }

.error-message {
  display: none;
  width: 100%;
  padding: 0.83333vw 1.66667vw   ;
  border: 1px solid red;
  color: red;
  border-radius: 5px;
  margin-bottom: 2.66667vw    ; }
  @media screen and (min-width: 1920px) {
    .error-message {
      padding: 16px 32px   ; } }
  @media screen and (max-width: 1024px) {
    .error-message {
      padding: 8.53333px 17.06667px   ; } }
  @media screen and (min-width: 1920px) {
    .error-message {
      margin-bottom: 51.2px    ; } }
  @media screen and (max-width: 1024px) {
    .error-message {
      margin-bottom: 27.30667px    ; } }

.error-message--visible {
  display: block; }

.download-button {
  display: flex;
  height: 3.33333vw    ;
  cursor: pointer; }
  @media screen and (min-width: 1920px) {
    .download-button {
      height: 64px    ; } }
  @media screen and (max-width: 1024px) {
    .download-button {
      height: 34.13333px    ; } }
  .download-button:hover .text::before {
    width: 100%; }
  .download-button .icon {
    height: 100%;
    flex: 0 0 25%;
    display: flex;
    align-items: center;
    background-color: #1D1D1B;
    align-self: center;
    justify-content: center; }
    .download-button .icon svg {
      width: 40%;
      height: auto; }
  .download-button .text {
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    flex: 1 1 75%;
    color: white;
    background-color: black;
    padding-left: 0.83333vw    ;
    font-size: 1vw    ; }
    @media screen and (min-width: 1920px) {
      .download-button .text {
        padding-left: 16px    ; } }
    @media screen and (max-width: 1024px) {
      .download-button .text {
        padding-left: 8.53333px    ; } }
    @media screen and (min-width: 1920px) {
      .download-button .text {
        font-size: 19.2px    ; } }
    @media screen and (max-width: 1024px) {
      .download-button .text {
        font-size: 10.24px    ; } }
    .download-button .text::before {
      content: '';
      display: flex;
      width: 0px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
      background-color: #1D1D1B;
      z-index: 1;
      transition: width 300ms ease; }
    .download-button .text .inner {
      z-index: 2; }
    .download-button .text .under-text {
      width: 100%;
      font-size: 0.83333vw    ;
      display: block;
      color: #bfbfbf; }
      @media screen and (min-width: 1920px) {
        .download-button .text .under-text {
          font-size: 16px    ; } }
      @media screen and (max-width: 1024px) {
        .download-button .text .under-text {
          font-size: 8.53333px    ; } }

/**
 * Menu
 */
.menu {
  display: flex;
  position: fixed;
  right: 4.16667vw    ;
  top: 5vw    ;
  z-index: 10;
  transition: top 300ms ease; }
  @media screen and (max-width: 640px) {
    .menu {
      display: none    ; } }
  @media screen and (min-width: 1920px) {
    .menu {
      right: 80px    ; } }
  @media screen and (max-width: 1024px) {
    .menu {
      right: 42.66667px    ; } }
  @media screen and (min-width: 1920px) {
    .menu {
      top: 96px    ; } }
  @media screen and (max-width: 1024px) {
    .menu {
      top: 51.2px    ; } }
  @media screen and (max-width: 640px) {
    .menu {
      width: 100%    ; } }
  @media screen and (max-width: 640px) {
    .menu {
      right: 0    ; } }
  @media screen and (max-width: 640px) {
    .menu {
      left: 0    ; } }
  @media screen and (max-width: 640px) {
    .menu {
      top: 80px    ; } }
  @media screen and (max-width: 640px) {
    .menu {
      padding: 10px 0px   ; } }
  @media screen and (max-width: 640px) {
    .menu {
      background-color: white    ; } }
  .menu.sticky {
    top: 1.91667vw    ; }
    @media screen and (min-width: 1920px) {
      .menu.sticky {
        top: 36.8px    ; } }
    @media screen and (max-width: 1024px) {
      .menu.sticky {
        top: 19.62667px    ; } }
    @media screen and (max-width: 640px) {
      .menu.sticky {
        top: 80px    ; } }
  .menu .menu-item {
    font-family: "DINPro", sans-serif;
    color: black;
    font-size: 1vw    ;
    line-height: 1.20833vw    ;
    margin-left: 1.66667vw    ;
    transform: translate3d(0, 0, 0);
    transition: transform 50ms ease; }
    @media screen and (min-width: 1920px) {
      .menu .menu-item {
        font-size: 19.2px    ; } }
    @media screen and (max-width: 1024px) {
      .menu .menu-item {
        font-size: 10.24px    ; } }
    @media screen and (max-width: 640px) {
      .menu .menu-item {
        display: block    ; } }
    @media screen and (max-width: 640px) {
      .menu .menu-item {
        font-size: 20px    ; } }
    @media screen and (min-width: 1920px) {
      .menu .menu-item {
        line-height: 23.2px    ; } }
    @media screen and (max-width: 1024px) {
      .menu .menu-item {
        line-height: 12.37333px    ; } }
    @media screen and (max-width: 640px) {
      .menu .menu-item {
        line-height: 30px    ; } }
    @media screen and (min-width: 1920px) {
      .menu .menu-item {
        margin-left: 32px    ; } }
    @media screen and (max-width: 1024px) {
      .menu .menu-item {
        margin-left: 17.06667px    ; } }
    @media screen and (max-width: 640px) {
      .menu .menu-item {
        margin-top: 5px    ; } }
    .menu .menu-item:not(.active):hover {
      transform: translate3d(0, 0, 0) skewX(-10deg); }
    .menu .menu-item.active {
      font-weight: 900; }
    .menu .menu-item.menu-item--blue {
      color: #2737E6; }

.hamburger {
  display: none;
  height: 80px;
  align-items: center;
  padding: 0 35px;
  justify-content: flex-end;
  position: fixed;
  width: 100%;
  z-index: 10; }
  @media screen and (max-width: 640px) {
    .hamburger {
      display: flex    ; } }
  .hamburger img {
    width: 35px;
    height: 35px;
    transition: transform 300ms ease; }
  .hamburger.open img {
    transform: rotate(-135deg); }

.sticky-header {
  position: fixed;
  display: block;
  width: 100%;
  height: 5vw    ;
  border-bottom: 1px solid #1D1D1B;
  background-color: white;
  z-index: 8;
  top: -5vw    ;
  left: 0;
  right: 0;
  transition: top 300ms ease; }
  @media screen and (min-width: 1920px) {
    .sticky-header {
      height: 96px    ; } }
  @media screen and (max-width: 1024px) {
    .sticky-header {
      height: 51.2px    ; } }
  @media screen and (max-width: 640px) {
    .sticky-header {
      height: 60px    ; } }
  @media screen and (min-width: 1920px) {
    .sticky-header {
      top: -96px    ; } }
  @media screen and (max-width: 1024px) {
    .sticky-header {
      top: -51.2px    ; } }
  @media screen and (max-width: 640px) {
    .sticky-header {
      top: -60px    ; } }
  .sticky-header.sticky {
    top: 0; }
    @media screen and (max-width: 640px) {
      .sticky-header.sticky {
        height: 80px    ; } }

/**
 * Menu
 */
footer {
  background-color: #1D1D1B;
  color: white;
  min-height: 16.66667vw    ;
  padding-top: 3.33333vw    ;
  padding-left: 9.16667vw    ;
  padding-right: 9.16667vw    ; }
  @media screen and (min-width: 1920px) {
    footer {
      min-height: 320px    ; } }
  @media screen and (max-width: 1024px) {
    footer {
      min-height: 170.66667px    ; } }
  @media screen and (min-width: 1920px) {
    footer {
      padding-top: 64px    ; } }
  @media screen and (max-width: 1024px) {
    footer {
      padding-top: 34.13333px    ; } }
  @media screen and (min-width: 1920px) {
    footer {
      padding-left: 176px    ; } }
  @media screen and (max-width: 1024px) {
    footer {
      padding-left: 93.86667px    ; } }
  @media screen and (min-width: 1920px) {
    footer {
      padding-right: 176px    ; } }
  @media screen and (max-width: 1024px) {
    footer {
      padding-right: 93.86667px    ; } }
  @media screen and (max-width: 640px) {
    footer {
      padding-left: 15px    ; } }
  @media screen and (max-width: 640px) {
    footer {
      padding-right: 15px    ; } }
  @media screen and (max-width: 1024px) {
    footer > div {
      margin-bottom: 40px    ; } }
  footer h3 {
    margin-bottom: 1.66667vw    ; }
    @media screen and (min-width: 1920px) {
      footer h3 {
        margin-bottom: 32px    ; } }
    @media screen and (max-width: 1024px) {
      footer h3 {
        margin-bottom: 17.06667px    ; } }
  footer p {
    color: white; }
    footer p a {
      color: white; }

/**
 * Login file, please import in other stylesheets
 */
.login-container {
  position: relative;
  width: 100%;
  max-width: 33.33333vw    ;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  border-left: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  padding: 0vw 2.91667vw   ; }
  @media screen and (min-width: 1920px) {
    .login-container {
      max-width: 640px    ; } }
  @media screen and (max-width: 1024px) {
    .login-container {
      max-width: 341.33333px    ; } }
  @media screen and (max-width: 640px) {
    .login-container {
      max-width: 100%    ; } }
  @media screen and (max-width: 640px) {
    .login-container {
      margin: 0    ; } }
  @media screen and (max-width: 640px) {
    .login-container {
      border-left: 0px solid #dcdcdc  ; } }
  @media screen and (max-width: 640px) {
    .login-container {
      border-right: 0px solid #dcdcdc  ; } }
  @media screen and (min-width: 1920px) {
    .login-container {
      padding: 0px 56px   ; } }
  @media screen and (max-width: 1024px) {
    .login-container {
      padding: 0px 29.86667px   ; } }
  @media screen and (max-width: 640px) {
    .login-container {
      padding: 0    ; } }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .login-container {
      height: 400px; } }
  .login-container .error-message {
    width: 100%; }
  .login-container img.plus {
    position: absolute;
    right: 0;
    top: 8.33333vw    ;
    width: 2.08333vw    ;
    height: 2.08333vw    ;
    transform: translateX(50%); }
    @media screen and (min-width: 1920px) {
      .login-container img.plus {
        top: 160px    ; } }
    @media screen and (max-width: 1024px) {
      .login-container img.plus {
        top: 85.33333px    ; } }
    @media screen and (min-width: 1920px) {
      .login-container img.plus {
        width: 40px    ; } }
    @media screen and (max-width: 1024px) {
      .login-container img.plus {
        width: 21.33333px    ; } }
    @media screen and (min-width: 1920px) {
      .login-container img.plus {
        height: 40px    ; } }
    @media screen and (max-width: 1024px) {
      .login-container img.plus {
        height: 21.33333px    ; } }
    @media screen and (max-width: 640px) {
      .login-container img.plus {
        display: none    ; } }
  .login-container img.cross {
    position: absolute;
    left: 0;
    bottom: 8.33333vw    ;
    width: 2.08333vw    ;
    height: 2.08333vw    ;
    transform: translateX(-50%); }
    @media screen and (min-width: 1920px) {
      .login-container img.cross {
        bottom: 160px    ; } }
    @media screen and (max-width: 1024px) {
      .login-container img.cross {
        bottom: 85.33333px    ; } }
    @media screen and (min-width: 1920px) {
      .login-container img.cross {
        width: 40px    ; } }
    @media screen and (max-width: 1024px) {
      .login-container img.cross {
        width: 21.33333px    ; } }
    @media screen and (min-width: 1920px) {
      .login-container img.cross {
        height: 40px    ; } }
    @media screen and (max-width: 1024px) {
      .login-container img.cross {
        height: 21.33333px    ; } }
    @media screen and (max-width: 640px) {
      .login-container img.cross {
        display: none    ; } }
  .login-container h1 {
    display: block;
    margin-bottom: 4.16667vw    ; }
    @media screen and (min-width: 1920px) {
      .login-container h1 {
        margin-bottom: 80px    ; } }
    @media screen and (max-width: 1024px) {
      .login-container h1 {
        margin-bottom: 42.66667px    ; } }
  .login-container p.message {
    width: 100%;
    margin-top: -2.5vw    ;
    margin-bottom: 3.33333vw    ; }
    @media screen and (min-width: 1920px) {
      .login-container p.message {
        margin-top: -48px    ; } }
    @media screen and (max-width: 1024px) {
      .login-container p.message {
        margin-top: -25.6px    ; } }
    @media screen and (min-width: 1920px) {
      .login-container p.message {
        margin-bottom: 64px    ; } }
    @media screen and (max-width: 1024px) {
      .login-container p.message {
        margin-bottom: 34.13333px    ; } }
    .login-container p.message:last-of-type {
      margin-bottom: 1.66667vw    ; }
      @media screen and (min-width: 1920px) {
        .login-container p.message:last-of-type {
          margin-bottom: 32px    ; } }
      @media screen and (max-width: 1024px) {
        .login-container p.message:last-of-type {
          margin-bottom: 17.06667px    ; } }
    .login-container p.message.message--error {
      color: red; }
    .login-container p.message.message--success {
      color: green; }
  .login-container form {
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
    .login-container form input[type="email"],
    .login-container form input[type="password"],
    .login-container form input[type="text"] {
      outline: none;
      width: 48%;
      border: 0;
      border-bottom: 1px solid #D8D8D8;
      font-family: "Rajdhani", sans-serif;
      font-weight: 300;
      font-size: 1vw    ;
      line-height: 1.16667vw    ;
      color: #4F5362;
      background-color: transparent; }
      @media screen and (max-width: 640px) {
        .login-container form input[type="email"],
        .login-container form input[type="password"],
        .login-container form input[type="text"] {
          width: 100%    ; } }
      @media screen and (min-width: 1920px) {
        .login-container form input[type="email"],
        .login-container form input[type="password"],
        .login-container form input[type="text"] {
          font-size: 19.2px    ; } }
      @media screen and (max-width: 1024px) {
        .login-container form input[type="email"],
        .login-container form input[type="password"],
        .login-container form input[type="text"] {
          font-size: 10.24px    ; } }
      @media screen and (max-width: 1440px) {
        .login-container form input[type="email"],
        .login-container form input[type="password"],
        .login-container form input[type="text"] {
          font-size: 14px    ; } }
      @media screen and (min-width: 1920px) {
        .login-container form input[type="email"],
        .login-container form input[type="password"],
        .login-container form input[type="text"] {
          line-height: 22.4px    ; } }
      @media screen and (max-width: 1024px) {
        .login-container form input[type="email"],
        .login-container form input[type="password"],
        .login-container form input[type="text"] {
          line-height: 11.94667px    ; } }
      @media screen and (max-width: 1440px) {
        .login-container form input[type="email"],
        .login-container form input[type="password"],
        .login-container form input[type="text"] {
          line-height: 16px    ; } }
      .login-container form input[type="email"].full,
      .login-container form input[type="password"].full,
      .login-container form input[type="text"].full {
        width: 100%; }
    .login-container form input[type="text"] {
      width: 100%;
      margin-right: 0 !important; }
    .login-container form input:not([type="button"]):not([type="submit"]):first-of-type {
      margin-right: 4%; }
      @media screen and (max-width: 640px) {
        .login-container form input:not([type="button"]):not([type="submit"]):first-of-type {
          margin-right: 0    ; } }
      @media screen and (max-width: 640px) {
        .login-container form input:not([type="button"]):not([type="submit"]):first-of-type {
          margin-bottom: 20px    ; } }
    .login-container form input[type="button"],
    .login-container form input[type="submit"] {
      width: 2.25vw    ;
      height: 1.41667vw    ;
      background-color: #2737E6;
      border: 0;
      outline: 0;
      cursor: pointer; }
      @media screen and (min-width: 1920px) {
        .login-container form input[type="button"],
        .login-container form input[type="submit"] {
          width: 43.2px    ; } }
      @media screen and (max-width: 1024px) {
        .login-container form input[type="button"],
        .login-container form input[type="submit"] {
          width: 23.04px    ; } }
      @media screen and (max-width: 1440px) {
        .login-container form input[type="button"],
        .login-container form input[type="submit"] {
          width: 32px    ; } }
      @media screen and (min-width: 1920px) {
        .login-container form input[type="button"],
        .login-container form input[type="submit"] {
          height: 27.2px    ; } }
      @media screen and (max-width: 1024px) {
        .login-container form input[type="button"],
        .login-container form input[type="submit"] {
          height: 14.50667px    ; } }
      @media screen and (max-width: 1440px) {
        .login-container form input[type="button"],
        .login-container form input[type="submit"] {
          height: 20px    ; } }
    .login-container form .submit {
      margin-top: 1.66667vw    ;
      position: relative;
      margin-left: auto; }
      @media screen and (min-width: 1920px) {
        .login-container form .submit {
          margin-top: 32px    ; } }
      @media screen and (max-width: 1024px) {
        .login-container form .submit {
          margin-top: 17.06667px    ; } }
      .login-container form .submit:hover::after {
        transform: translateX(25%) rotate(-45deg); }
      .login-container form .submit::after {
        content: '';
        width: 0.33333vw    ;
        height: 0.33333vw    ;
        border-style: solid;
        border-color: white;
        border-width: 0vw 0.08333vw 0.08333vw 0vw ;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        transform: translateX(-25%) rotate(-45deg);
        transition: transform 200ms ease; }
        @media screen and (min-width: 1920px) {
          .login-container form .submit::after {
            width: 6.4px    ; } }
        @media screen and (max-width: 1024px) {
          .login-container form .submit::after {
            width: 3.41333px    ; } }
        @media screen and (max-width: 1440px) {
          .login-container form .submit::after {
            width: 5px    ; } }
        @media screen and (min-width: 1920px) {
          .login-container form .submit::after {
            height: 6.4px    ; } }
        @media screen and (max-width: 1024px) {
          .login-container form .submit::after {
            height: 3.41333px    ; } }
        @media screen and (max-width: 1440px) {
          .login-container form .submit::after {
            height: 5px    ; } }
        @media screen and (min-width: 1920px) {
          .login-container form .submit::after {
            border-width: 0px 1.6px 1.6px 0px ; } }
        @media screen and (max-width: 1024px) {
          .login-container form .submit::after {
            border-width: 0px 0.85333px 0.85333px 0px ; } }
  .login-container a {
    font-size: 0.66667vw    ;
    margin-top: 1.66667vw    ;
    font-weight: 700;
    color: #1D1D1B;
    transition: color 200ms ease;
    display: block;
    width: 100%; }
    @media screen and (min-width: 1920px) {
      .login-container a {
        font-size: 12.8px    ; } }
    @media screen and (max-width: 1024px) {
      .login-container a {
        font-size: 6.82667px    ; } }
    @media screen and (max-width: 1440px) {
      .login-container a {
        font-size: 12px    ; } }
    @media screen and (min-width: 1920px) {
      .login-container a {
        margin-top: 32px    ; } }
    @media screen and (max-width: 1024px) {
      .login-container a {
        margin-top: 17.06667px    ; } }
    .login-container a:hover {
      color: black; }

/**
 * Overview
 */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .inner-container--overview {
    height: 400px; } }

.inner-container--overview p {
  font-family: "DINPro", sans-serif;
  font-size: 3.75vw    ;
  line-height: 4.5vw    ;
  color: #1D1D1B;
  padding-bottom: 6.66667vw    ; }
  @media screen and (min-width: 1920px) {
    .inner-container--overview p {
      font-size: 72px    ; } }
  @media screen and (max-width: 1024px) {
    .inner-container--overview p {
      font-size: 38.4px    ; } }
  @media screen and (min-width: 1920px) {
    .inner-container--overview p {
      line-height: 86.4px    ; } }
  @media screen and (max-width: 1024px) {
    .inner-container--overview p {
      line-height: 46.08px    ; } }
  @media screen and (min-width: 1920px) {
    .inner-container--overview p {
      padding-bottom: 128px    ; } }
  @media screen and (max-width: 1024px) {
    .inner-container--overview p {
      padding-bottom: 68.26667px    ; } }
  .inner-container--overview p span[data-fy-field="first_name"] {
    display: inline-block;
    overflow: hidden;
    max-width: 25vw    ;
    vertical-align: bottom;
    text-overflow: ellipsis;
    white-space: nowrap; }
    @media screen and (min-width: 1920px) {
      .inner-container--overview p span[data-fy-field="first_name"] {
        max-width: 480px    ; } }
    @media screen and (max-width: 1024px) {
      .inner-container--overview p span[data-fy-field="first_name"] {
        max-width: 256px    ; } }
  .inner-container--overview p span.highlight {
    position: relative;
    display: inline-block;
    background-color: black;
    color: white;
    padding: 0vw 0.41667vw 0.41667vw 0.41667vw ;
    margin: 0.33333vw 0vw 0vw 0vw ; }
    @media screen and (min-width: 1920px) {
      .inner-container--overview p span.highlight {
        padding: 0px 8px 8px 8px ; } }
    @media screen and (max-width: 1024px) {
      .inner-container--overview p span.highlight {
        padding: 0px 4.26667px 4.26667px 4.26667px ; } }
    @media screen and (min-width: 1920px) {
      .inner-container--overview p span.highlight {
        margin: 6.4px 0px 0px 0px ; } }
    @media screen and (max-width: 1024px) {
      .inner-container--overview p span.highlight {
        margin: 3.41333px 0px 0px 0px ; } }
    .inner-container--overview p span.highlight a {
      color: white; }
    .inner-container--overview p span.highlight .popup {
      width: 12.5vw    ;
      text-align: center; }
      @media screen and (min-width: 1920px) {
        .inner-container--overview p span.highlight .popup {
          width: 240px    ; } }
      @media screen and (max-width: 1024px) {
        .inner-container--overview p span.highlight .popup {
          width: 128px    ; } }

.line {
  display: block;
  height: 100%;
  width: 1px;
  background-color: #dcdcdc;
  position: absolute;
  left: 33.33333vw    ;
  z-index: -1; }
  @media screen and (max-width: 1024px) {
    .line {
      display: none    ; } }
  @media screen and (min-width: 1920px) {
    .line {
      left: 640px    ; } }
  @media screen and (max-width: 1024px) {
    .line {
      left: 341.33333px    ; } }

.overview img.plus {
  position: absolute;
  top: 8.33333vw    ;
  left: 33.33333vw    ;
  width: 2.08333vw    ;
  height: 2.08333vw    ;
  transform: translateX(-50%); }
  @media screen and (max-width: 1024px) {
    .overview img.plus {
      display: none    ; } }
  @media screen and (min-width: 1920px) {
    .overview img.plus {
      top: 160px    ; } }
  @media screen and (max-width: 1024px) {
    .overview img.plus {
      top: 85.33333px    ; } }
  @media screen and (min-width: 1920px) {
    .overview img.plus {
      left: 640px    ; } }
  @media screen and (max-width: 1024px) {
    .overview img.plus {
      left: 341.33333px    ; } }
  @media screen and (min-width: 1920px) {
    .overview img.plus {
      width: 40px    ; } }
  @media screen and (max-width: 1024px) {
    .overview img.plus {
      width: 21.33333px    ; } }
  @media screen and (min-width: 1920px) {
    .overview img.plus {
      height: 40px    ; } }
  @media screen and (max-width: 1024px) {
    .overview img.plus {
      height: 21.33333px    ; } }
  @media screen and (max-width: 640px) {
    .overview img.plus {
      display: none    ; } }

.overview img.cross {
  position: absolute;
  left: 33.33333vw    ;
  bottom: 8.33333vw    ;
  width: 2.08333vw    ;
  height: 2.08333vw    ;
  transform: translateX(-50%); }
  @media screen and (max-width: 1024px) {
    .overview img.cross {
      display: none    ; } }
  @media screen and (min-width: 1920px) {
    .overview img.cross {
      left: 640px    ; } }
  @media screen and (max-width: 1024px) {
    .overview img.cross {
      left: 341.33333px    ; } }
  @media screen and (min-width: 1920px) {
    .overview img.cross {
      bottom: 160px    ; } }
  @media screen and (max-width: 1024px) {
    .overview img.cross {
      bottom: 85.33333px    ; } }
  @media screen and (min-width: 1920px) {
    .overview img.cross {
      width: 40px    ; } }
  @media screen and (max-width: 1024px) {
    .overview img.cross {
      width: 21.33333px    ; } }
  @media screen and (min-width: 1920px) {
    .overview img.cross {
      height: 40px    ; } }
  @media screen and (max-width: 1024px) {
    .overview img.cross {
      height: 21.33333px    ; } }
  @media screen and (max-width: 640px) {
    .overview img.cross {
      display: none    ; } }

/**
 * Settings
 */
.inner-container--settings {
  width: 39.16667vw    ; }
  @media screen and (min-width: 1920px) {
    .inner-container--settings {
      width: 752px    ; } }
  @media screen and (max-width: 1024px) {
    .inner-container--settings {
      width: 401.06667px    ; } }
  .inner-container--settings form {
    margin-top: 2.91667vw    ; }
    @media screen and (min-width: 1920px) {
      .inner-container--settings form {
        margin-top: 56px    ; } }
    @media screen and (max-width: 1024px) {
      .inner-container--settings form {
        margin-top: 29.86667px    ; } }
    .inner-container--settings form > label {
      font-weight: 900;
      font-size: 0.83333vw    ;
      line-height: 1vw    ;
      letter-spacing: 0.04167vw    ;
      margin-bottom: 2.08333vw    ; }
      @media screen and (min-width: 1920px) {
        .inner-container--settings form > label {
          font-size: 16px    ; } }
      @media screen and (max-width: 1024px) {
        .inner-container--settings form > label {
          font-size: 8.53333px    ; } }
      @media screen and (min-width: 1920px) {
        .inner-container--settings form > label {
          line-height: 19.2px    ; } }
      @media screen and (max-width: 1024px) {
        .inner-container--settings form > label {
          line-height: 10.24px    ; } }
      @media screen and (min-width: 1920px) {
        .inner-container--settings form > label {
          letter-spacing: 0.8px    ; } }
      @media screen and (max-width: 1024px) {
        .inner-container--settings form > label {
          letter-spacing: 0.42667px    ; } }
      @media screen and (min-width: 1920px) {
        .inner-container--settings form > label {
          margin-bottom: 40px    ; } }
      @media screen and (max-width: 1024px) {
        .inner-container--settings form > label {
          margin-bottom: 21.33333px    ; } }
      .inner-container--settings form > label:not(.col-12):nth-of-type(2n-1) {
        padding-right: 3.125vw    ; }
        @media screen and (min-width: 1920px) {
          .inner-container--settings form > label:not(.col-12):nth-of-type(2n-1) {
            padding-right: 60px    ; } }
        @media screen and (max-width: 1024px) {
          .inner-container--settings form > label:not(.col-12):nth-of-type(2n-1) {
            padding-right: 32px    ; } }
        @media screen and (max-width: 640px) {
          .inner-container--settings form > label:not(.col-12):nth-of-type(2n-1) {
            padding-right: 0    ; } }
      .inner-container--settings form > label:nth-of-type(2n) {
        padding-left: 3.125vw    ; }
        @media screen and (min-width: 1920px) {
          .inner-container--settings form > label:nth-of-type(2n) {
            padding-left: 60px    ; } }
        @media screen and (max-width: 1024px) {
          .inner-container--settings form > label:nth-of-type(2n) {
            padding-left: 32px    ; } }
        @media screen and (max-width: 640px) {
          .inner-container--settings form > label:nth-of-type(2n) {
            padding-left: 0    ; } }
      .inner-container--settings form > label input:not([type="checkbox"]):not([type="submit"]):not([type="radio"]),
      .inner-container--settings form > label select {
        color: #4F5362;
        width: 100%;
        background-color: transparent;
        height: 2.91667vw    ;
        font-weight: 300;
        font-size: 1.33333vw    ;
        letter-spacing: 0.025vw    ;
        line-height: 1.6vw    ;
        border: 0;
        border-bottom: 1px solid #dcdcdc;
        margin-bottom: 0.83333vw    ; }
        @media screen and (min-width: 1920px) {
          .inner-container--settings form > label input:not([type="checkbox"]):not([type="submit"]):not([type="radio"]),
          .inner-container--settings form > label select {
            height: 56px    ; } }
        @media screen and (max-width: 1024px) {
          .inner-container--settings form > label input:not([type="checkbox"]):not([type="submit"]):not([type="radio"]),
          .inner-container--settings form > label select {
            height: 29.86667px    ; } }
        @media screen and (min-width: 1920px) {
          .inner-container--settings form > label input:not([type="checkbox"]):not([type="submit"]):not([type="radio"]),
          .inner-container--settings form > label select {
            font-size: 25.6px    ; } }
        @media screen and (max-width: 1024px) {
          .inner-container--settings form > label input:not([type="checkbox"]):not([type="submit"]):not([type="radio"]),
          .inner-container--settings form > label select {
            font-size: 13.65333px    ; } }
        @media screen and (min-width: 1920px) {
          .inner-container--settings form > label input:not([type="checkbox"]):not([type="submit"]):not([type="radio"]),
          .inner-container--settings form > label select {
            letter-spacing: 0.48px    ; } }
        @media screen and (max-width: 1024px) {
          .inner-container--settings form > label input:not([type="checkbox"]):not([type="submit"]):not([type="radio"]),
          .inner-container--settings form > label select {
            letter-spacing: 0.256px    ; } }
        @media screen and (min-width: 1920px) {
          .inner-container--settings form > label input:not([type="checkbox"]):not([type="submit"]):not([type="radio"]),
          .inner-container--settings form > label select {
            line-height: 30.72px    ; } }
        @media screen and (max-width: 1024px) {
          .inner-container--settings form > label input:not([type="checkbox"]):not([type="submit"]):not([type="radio"]),
          .inner-container--settings form > label select {
            line-height: 16.384px    ; } }
        @media screen and (min-width: 1920px) {
          .inner-container--settings form > label input:not([type="checkbox"]):not([type="submit"]):not([type="radio"]),
          .inner-container--settings form > label select {
            margin-bottom: 16px    ; } }
        @media screen and (max-width: 1024px) {
          .inner-container--settings form > label input:not([type="checkbox"]):not([type="submit"]):not([type="radio"]),
          .inner-container--settings form > label select {
            margin-bottom: 8.53333px    ; } }
        .inner-container--settings form > label input:not([type="checkbox"]):not([type="submit"]):not([type="radio"])::placeholder,
        .inner-container--settings form > label select::placeholder {
          color: #4f536270; }
      .inner-container--settings form > label input:disabled {
        background-color: #dcdcdc47 !important;
        cursor: not-allowed; }
      .inner-container--settings form > label .select-wrapper {
        position: relative; }
        .inner-container--settings form > label .select-wrapper::after, .inner-container--settings form > label .select-wrapper::before {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0.25vw 0.125vw   ;
          position: absolute;
          right: 0.41667vw    ;
          top: 0;
          bottom: 0;
          margin: auto; }
          @media screen and (min-width: 1920px) {
            .inner-container--settings form > label .select-wrapper::after, .inner-container--settings form > label .select-wrapper::before {
              border-width: 4.8px 2.4px   ; } }
          @media screen and (max-width: 1024px) {
            .inner-container--settings form > label .select-wrapper::after, .inner-container--settings form > label .select-wrapper::before {
              border-width: 2.56px 1.28px   ; } }
          @media screen and (min-width: 1920px) {
            .inner-container--settings form > label .select-wrapper::after, .inner-container--settings form > label .select-wrapper::before {
              right: 8px    ; } }
          @media screen and (max-width: 1024px) {
            .inner-container--settings form > label .select-wrapper::after, .inner-container--settings form > label .select-wrapper::before {
              right: 4.26667px    ; } }
        .inner-container--settings form > label .select-wrapper::after {
          border-color: #707070 transparent transparent transparent;
          transform: translateY(30%); }
        .inner-container--settings form > label .select-wrapper::before {
          border-color: transparent transparent #707070 transparent;
          transform: translateY(-130%); }
      .inner-container--settings form > label input[type="checkbox"] {
        vertical-align: middle;
        margin-right: 0.41667vw    ; }
        @media screen and (min-width: 1920px) {
          .inner-container--settings form > label input[type="checkbox"] {
            margin-right: 8px    ; } }
        @media screen and (max-width: 1024px) {
          .inner-container--settings form > label input[type="checkbox"] {
            margin-right: 4.26667px    ; } }

/**
 * Subscriptions
 */
.inner-container--subscriptions {
  width: 39.16667vw    ; }
  @media screen and (min-width: 1920px) {
    .inner-container--subscriptions {
      width: 752px    ; } }
  @media screen and (max-width: 1024px) {
    .inner-container--subscriptions {
      width: 401.06667px    ; } }
  .inner-container--subscriptions p {
    margin-bottom: 3.33333vw    ; }
    @media screen and (min-width: 1920px) {
      .inner-container--subscriptions p {
        margin-bottom: 64px    ; } }
    @media screen and (max-width: 1024px) {
      .inner-container--subscriptions p {
        margin-bottom: 34.13333px    ; } }
  .inner-container--subscriptions section {
    margin-bottom: 4.16667vw    ; }
    @media screen and (min-width: 1920px) {
      .inner-container--subscriptions section {
        margin-bottom: 80px    ; } }
    @media screen and (max-width: 1024px) {
      .inner-container--subscriptions section {
        margin-bottom: 42.66667px    ; } }
  .inner-container--subscriptions form {
    width: 100%; }
  .inner-container--subscriptions .duration {
    display: block;
    margin-bottom: 1.25vw    ;
    cursor: pointer; }
    @media screen and (min-width: 1920px) {
      .inner-container--subscriptions .duration {
        margin-bottom: 24px    ; } }
    @media screen and (max-width: 1024px) {
      .inner-container--subscriptions .duration {
        margin-bottom: 12.8px    ; } }
    .inner-container--subscriptions .duration input[type="radio"] {
      display: none; }
    .inner-container--subscriptions .duration .inner {
      display: flex;
      width: 95%;
      height: 4.16667vw    ;
      color: white;
      transform-origin: left center;
      transition: color 200ms ease, width 200ms ease; }
      @media screen and (min-width: 1920px) {
        .inner-container--subscriptions .duration .inner {
          height: 80px    ; } }
      @media screen and (max-width: 1024px) {
        .inner-container--subscriptions .duration .inner {
          height: 42.66667px    ; } }
      .inner-container--subscriptions .duration .inner .title {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 7.08333vw    ;
        flex-grow: 0;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        align-content: center;
        font-size: 1.16667vw    ;
        background-color: #1E1E1E;
        transition: background-color 200ms ease; }
        @media screen and (min-width: 1920px) {
          .inner-container--subscriptions .duration .inner .title {
            flex-basis: 136px    ; } }
        @media screen and (max-width: 1024px) {
          .inner-container--subscriptions .duration .inner .title {
            flex-basis: 72.53333px    ; } }
        @media screen and (min-width: 1920px) {
          .inner-container--subscriptions .duration .inner .title {
            font-size: 22.4px    ; } }
        @media screen and (max-width: 1024px) {
          .inner-container--subscriptions .duration .inner .title {
            font-size: 11.94667px    ; } }
        .inner-container--subscriptions .duration .inner .title span.price {
          display: flex;
          justify-content: center;
          width: 100%;
          font-weight: 900; }
      .inner-container--subscriptions .duration .inner .content {
        display: flex;
        flex-grow: 1;
        flex-shrink: 1;
        position: relative;
        background-color: black;
        align-items: center;
        font-size: 0.83333vw    ;
        padding-left: 1.66667vw    ;
        padding-right: 1.66667vw    ;
        transition: background-color 200ms ease, padding-right 200ms ease; }
        @media screen and (min-width: 1920px) {
          .inner-container--subscriptions .duration .inner .content {
            font-size: 16px    ; } }
        @media screen and (max-width: 1024px) {
          .inner-container--subscriptions .duration .inner .content {
            font-size: 8.53333px    ; } }
        @media screen and (max-width: 1440px) {
          .inner-container--subscriptions .duration .inner .content {
            font-size: 12px    ; } }
        @media screen and (min-width: 1920px) {
          .inner-container--subscriptions .duration .inner .content {
            padding-left: 32px    ; } }
        @media screen and (max-width: 1024px) {
          .inner-container--subscriptions .duration .inner .content {
            padding-left: 17.06667px    ; } }
        @media screen and (min-width: 1920px) {
          .inner-container--subscriptions .duration .inner .content {
            padding-right: 32px    ; } }
        @media screen and (max-width: 1024px) {
          .inner-container--subscriptions .duration .inner .content {
            padding-right: 17.06667px    ; } }
    .inner-container--subscriptions .duration input[type="radio"]:checked + .inner {
      width: 100%; }
      .inner-container--subscriptions .duration input[type="radio"]:checked + .inner .title {
        background-color: #5260FF; }
      .inner-container--subscriptions .duration input[type="radio"]:checked + .inner .content {
        background-color: #2737E6;
        padding-right: 3.33333vw    ; }
        @media screen and (min-width: 1920px) {
          .inner-container--subscriptions .duration input[type="radio"]:checked + .inner .content {
            padding-right: 64px    ; } }
        @media screen and (max-width: 1024px) {
          .inner-container--subscriptions .duration input[type="radio"]:checked + .inner .content {
            padding-right: 34.13333px    ; } }
        .inner-container--subscriptions .duration input[type="radio"]:checked + .inner .content::after {
          content: '';
          width: 1.16667vw    ;
          height: 0.91667vw    ;
          background-image: url("/dist/images/check.svg");
          background-size: contain;
          background-position: center;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto 0;
          right: 1.66667vw    ; }
          @media screen and (min-width: 1920px) {
            .inner-container--subscriptions .duration input[type="radio"]:checked + .inner .content::after {
              width: 22.4px    ; } }
          @media screen and (max-width: 1024px) {
            .inner-container--subscriptions .duration input[type="radio"]:checked + .inner .content::after {
              width: 11.94667px    ; } }
          @media screen and (min-width: 1920px) {
            .inner-container--subscriptions .duration input[type="radio"]:checked + .inner .content::after {
              height: 17.6px    ; } }
          @media screen and (max-width: 1024px) {
            .inner-container--subscriptions .duration input[type="radio"]:checked + .inner .content::after {
              height: 9.38667px    ; } }
          @media screen and (min-width: 1920px) {
            .inner-container--subscriptions .duration input[type="radio"]:checked + .inner .content::after {
              right: 32px    ; } }
          @media screen and (max-width: 1024px) {
            .inner-container--subscriptions .duration input[type="radio"]:checked + .inner .content::after {
              right: 17.06667px    ; } }
    .inner-container--subscriptions .duration input[type="radio"]:not(:checked) + .inner:hover {
      width: 97%; }
  .inner-container--subscriptions .check {
    color: #1D1D1B; }
    .inner-container--subscriptions .check input {
      vertical-align: middle;
      margin-right: 0.41667vw    ; }
      @media screen and (min-width: 1920px) {
        .inner-container--subscriptions .check input {
          margin-right: 8px    ; } }
      @media screen and (max-width: 1024px) {
        .inner-container--subscriptions .check input {
          margin-right: 4.26667px    ; } }

/**
 * Invoices
 */
.inner-container--invoices {
  width: 39.16667vw    ; }
  @media screen and (min-width: 1920px) {
    .inner-container--invoices {
      width: 752px    ; } }
  @media screen and (max-width: 1024px) {
    .inner-container--invoices {
      width: 401.06667px    ; } }
  .inner-container--invoices > h1 {
    width: 100%; }
  .inner-container--invoices > p {
    width: 100%; }
  .inner-container--invoices .date-picker {
    border: 1px solid #dcdcdc;
    width: 10.83333vw    ;
    position: relative;
    margin-top: 1.25vw    ;
    margin-right: 1.25vw    ;
    margin-bottom: 0.83333vw    ; }
    @media screen and (min-width: 1920px) {
      .inner-container--invoices .date-picker {
        width: 208px    ; } }
    @media screen and (max-width: 1024px) {
      .inner-container--invoices .date-picker {
        width: 110.93333px    ; } }
    @media screen and (max-width: 1440px) {
      .inner-container--invoices .date-picker {
        width: 156px    ; } }
    @media screen and (min-width: 1920px) {
      .inner-container--invoices .date-picker {
        margin-top: 24px    ; } }
    @media screen and (max-width: 1024px) {
      .inner-container--invoices .date-picker {
        margin-top: 12.8px    ; } }
    @media screen and (min-width: 1920px) {
      .inner-container--invoices .date-picker {
        margin-right: 24px    ; } }
    @media screen and (max-width: 1024px) {
      .inner-container--invoices .date-picker {
        margin-right: 12.8px    ; } }
    @media screen and (min-width: 1920px) {
      .inner-container--invoices .date-picker {
        margin-bottom: 16px    ; } }
    @media screen and (max-width: 1024px) {
      .inner-container--invoices .date-picker {
        margin-bottom: 8.53333px    ; } }
    .inner-container--invoices .date-picker::before {
      content: '';
      display: block;
      width: 1px;
      height: 60%;
      position: absolute;
      background-color: #dcdcdc;
      left: 4.58333vw    ;
      top: 0;
      bottom: 0;
      margin: auto; }
      @media screen and (min-width: 1920px) {
        .inner-container--invoices .date-picker::before {
          left: 88px    ; } }
      @media screen and (max-width: 1024px) {
        .inner-container--invoices .date-picker::before {
          left: 46.93333px    ; } }
      @media screen and (max-width: 1440px) {
        .inner-container--invoices .date-picker::before {
          left: 66px    ; } }
    .inner-container--invoices .date-picker::after {
      content: '';
      background-image: url("/dist/images/calendar.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      display: block;
      width: 15px;
      height: 15px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0.58333vw    ;
      margin: auto; }
      @media screen and (min-width: 1920px) {
        .inner-container--invoices .date-picker::after {
          right: 11.2px    ; } }
      @media screen and (max-width: 1024px) {
        .inner-container--invoices .date-picker::after {
          right: 5.97333px    ; } }
      @media screen and (max-width: 1440px) {
        .inner-container--invoices .date-picker::after {
          right: 8.4px    ; } }
    .inner-container--invoices .date-picker input {
      width: 9.16667vw    ;
      outline: 0;
      border: 0;
      height: 2.08333vw    ;
      font-family: "DINPro", sans-serif;
      font-size: 0.625vw    ;
      line-height: 0.75vw    ;
      letter-spacing: 0.0275vw    ;
      text-align: center; }
      @media screen and (min-width: 1920px) {
        .inner-container--invoices .date-picker input {
          width: 176px    ; } }
      @media screen and (max-width: 1024px) {
        .inner-container--invoices .date-picker input {
          width: 93.86667px    ; } }
      @media screen and (max-width: 1440px) {
        .inner-container--invoices .date-picker input {
          width: 132px    ; } }
      @media screen and (min-width: 1920px) {
        .inner-container--invoices .date-picker input {
          height: 40px    ; } }
      @media screen and (max-width: 1024px) {
        .inner-container--invoices .date-picker input {
          height: 21.33333px    ; } }
      @media screen and (max-width: 1440px) {
        .inner-container--invoices .date-picker input {
          height: 30px    ; } }
      @media screen and (min-width: 1920px) {
        .inner-container--invoices .date-picker input {
          font-size: 12px    ; } }
      @media screen and (max-width: 1024px) {
        .inner-container--invoices .date-picker input {
          font-size: 6.4px    ; } }
      @media screen and (max-width: 1440px) {
        .inner-container--invoices .date-picker input {
          font-size: 9px    ; } }
      @media screen and (min-width: 1920px) {
        .inner-container--invoices .date-picker input {
          line-height: 14.4px    ; } }
      @media screen and (max-width: 1024px) {
        .inner-container--invoices .date-picker input {
          line-height: 7.68px    ; } }
      @media screen and (max-width: 1440px) {
        .inner-container--invoices .date-picker input {
          line-height: 10.8px    ; } }
      @media screen and (min-width: 1920px) {
        .inner-container--invoices .date-picker input {
          letter-spacing: 0.528px    ; } }
      @media screen and (max-width: 1024px) {
        .inner-container--invoices .date-picker input {
          letter-spacing: 0.2816px    ; } }
      @media screen and (max-width: 1440px) {
        .inner-container--invoices .date-picker input {
          letter-spacing: 0.396px    ; } }
  .inner-container--invoices .dropdown {
    border: 1px solid #dcdcdc;
    width: 10.83333vw    ;
    position: relative;
    margin-top: 1.25vw    ;
    margin-right: 1.25vw    ;
    margin-bottom: 0.83333vw    ; }
    @media screen and (min-width: 1920px) {
      .inner-container--invoices .dropdown {
        width: 208px    ; } }
    @media screen and (max-width: 1024px) {
      .inner-container--invoices .dropdown {
        width: 110.93333px    ; } }
    @media screen and (max-width: 1440px) {
      .inner-container--invoices .dropdown {
        width: 156px    ; } }
    @media screen and (min-width: 1920px) {
      .inner-container--invoices .dropdown {
        margin-top: 24px    ; } }
    @media screen and (max-width: 1024px) {
      .inner-container--invoices .dropdown {
        margin-top: 12.8px    ; } }
    @media screen and (min-width: 1920px) {
      .inner-container--invoices .dropdown {
        margin-right: 24px    ; } }
    @media screen and (max-width: 1024px) {
      .inner-container--invoices .dropdown {
        margin-right: 12.8px    ; } }
    @media screen and (min-width: 1920px) {
      .inner-container--invoices .dropdown {
        margin-bottom: 16px    ; } }
    @media screen and (max-width: 1024px) {
      .inner-container--invoices .dropdown {
        margin-bottom: 8.53333px    ; } }
    .inner-container--invoices .dropdown::after {
      content: '';
      width: 0;
      height: 0;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0.41667vw    ;
      border-style: solid;
      border-color: #1D1D1B transparent transparent transparent;
      margin: auto; }
      @media screen and (min-width: 1920px) {
        .inner-container--invoices .dropdown::after {
          right: 8px    ; } }
      @media screen and (max-width: 1024px) {
        .inner-container--invoices .dropdown::after {
          right: 4.26667px    ; } }
      @media screen and (min-width: 1920px) {
        .inner-container--invoices .dropdown::after { } }
      @media screen and (max-width: 1024px) {
        .inner-container--invoices .dropdown::after { } }
    .inner-container--invoices .dropdown select {
      appearance: none;
      border: 0;
      outline: 0;
      width: 100%;
      height: 2.08333vw    ;
      padding-left: 1.25vw    ;
      padding-right: 1.25vw    ; }
      @media screen and (min-width: 1920px) {
        .inner-container--invoices .dropdown select {
          height: 40px    ; } }
      @media screen and (max-width: 1024px) {
        .inner-container--invoices .dropdown select {
          height: 21.33333px    ; } }
      @media screen and (max-width: 1440px) {
        .inner-container--invoices .dropdown select {
          height: 30px    ; } }
      @media screen and (min-width: 1920px) {
        .inner-container--invoices .dropdown select {
          padding-left: 24px    ; } }
      @media screen and (max-width: 1024px) {
        .inner-container--invoices .dropdown select {
          padding-left: 12.8px    ; } }
      @media screen and (min-width: 1920px) {
        .inner-container--invoices .dropdown select {
          padding-right: 24px    ; } }
      @media screen and (max-width: 1024px) {
        .inner-container--invoices .dropdown select {
          padding-right: 12.8px    ; } }
  .inner-container--invoices .invoices-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 3.33333vw    ; }
    @media screen and (min-width: 1920px) {
      .inner-container--invoices .invoices-table {
        margin-top: 64px    ; } }
    @media screen and (max-width: 1024px) {
      .inner-container--invoices .invoices-table {
        margin-top: 34.13333px    ; } }
    .inner-container--invoices .invoices-table .invoices-table--header,
    .inner-container--invoices .invoices-table .invoices-table--row {
      display: flex;
      align-items: center;
      height: 2.5vw    ;
      border-bottom: 1px solid #dcdcdc;
      font-weight: 500;
      font-size: 0.83333vw    ; }
      @media screen and (min-width: 1920px) {
        .inner-container--invoices .invoices-table .invoices-table--header,
        .inner-container--invoices .invoices-table .invoices-table--row {
          height: 48px    ; } }
      @media screen and (max-width: 1024px) {
        .inner-container--invoices .invoices-table .invoices-table--header,
        .inner-container--invoices .invoices-table .invoices-table--row {
          height: 25.6px    ; } }
      @media screen and (max-width: 1440px) {
        .inner-container--invoices .invoices-table .invoices-table--header,
        .inner-container--invoices .invoices-table .invoices-table--row {
          height: 36px    ; } }
      @media screen and (min-width: 1920px) {
        .inner-container--invoices .invoices-table .invoices-table--header,
        .inner-container--invoices .invoices-table .invoices-table--row {
          font-size: 16px    ; } }
      @media screen and (max-width: 1024px) {
        .inner-container--invoices .invoices-table .invoices-table--header,
        .inner-container--invoices .invoices-table .invoices-table--row {
          font-size: 8.53333px    ; } }
      @media screen and (max-width: 1440px) {
        .inner-container--invoices .invoices-table .invoices-table--header,
        .inner-container--invoices .invoices-table .invoices-table--row {
          font-size: 12px    ; } }
      .inner-container--invoices .invoices-table .invoices-table--header > div:nth-child(1),
      .inner-container--invoices .invoices-table .invoices-table--row > div:nth-child(1) {
        flex: 1 5 21%;
        color: #1D1D1B; }
      .inner-container--invoices .invoices-table .invoices-table--header > div:nth-child(2),
      .inner-container--invoices .invoices-table .invoices-table--row > div:nth-child(2) {
        flex: 1 5 21%; }
      .inner-container--invoices .invoices-table .invoices-table--header > div:nth-child(3),
      .inner-container--invoices .invoices-table .invoices-table--row > div:nth-child(3) {
        flex: 1 5 21%; }
      .inner-container--invoices .invoices-table .invoices-table--header > div:nth-child(4),
      .inner-container--invoices .invoices-table .invoices-table--row > div:nth-child(4) {
        flex: 1 5 21%; }
      .inner-container--invoices .invoices-table .invoices-table--header > div:nth-child(5),
      .inner-container--invoices .invoices-table .invoices-table--row > div:nth-child(5) {
        flex: 1 1 16%;
        text-align: center;
        overflow: hidden; }
        @media screen and (max-width: 1440px) {
          .inner-container--invoices .invoices-table .invoices-table--header > div:nth-child(5),
          .inner-container--invoices .invoices-table .invoices-table--row > div:nth-child(5) {
            text-align: left    ; } }
      .inner-container--invoices .invoices-table .invoices-table--header a.button,
      .inner-container--invoices .invoices-table .invoices-table--row a.button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 1.41667vw    ;
        text-align: center;
        width: 100%;
        padding: 0;
        font-weight: 700;
        font-size: 0.66667vw    ;
        line-height: 0.8vw    ;
        letter-spacing: 0.20833vw    ; }
        @media screen and (min-width: 1920px) {
          .inner-container--invoices .invoices-table .invoices-table--header a.button,
          .inner-container--invoices .invoices-table .invoices-table--row a.button {
            height: 27.2px    ; } }
        @media screen and (max-width: 1024px) {
          .inner-container--invoices .invoices-table .invoices-table--header a.button,
          .inner-container--invoices .invoices-table .invoices-table--row a.button {
            height: 14.50667px    ; } }
        @media screen and (max-width: 1440px) {
          .inner-container--invoices .invoices-table .invoices-table--header a.button,
          .inner-container--invoices .invoices-table .invoices-table--row a.button {
            height: 20.4px    ; } }
        @media screen and (min-width: 1920px) {
          .inner-container--invoices .invoices-table .invoices-table--header a.button,
          .inner-container--invoices .invoices-table .invoices-table--row a.button {
            font-size: 12.8px    ; } }
        @media screen and (max-width: 1024px) {
          .inner-container--invoices .invoices-table .invoices-table--header a.button,
          .inner-container--invoices .invoices-table .invoices-table--row a.button {
            font-size: 6.82667px    ; } }
        @media screen and (min-width: 1920px) {
          .inner-container--invoices .invoices-table .invoices-table--header a.button,
          .inner-container--invoices .invoices-table .invoices-table--row a.button {
            line-height: 15.36px    ; } }
        @media screen and (max-width: 1024px) {
          .inner-container--invoices .invoices-table .invoices-table--header a.button,
          .inner-container--invoices .invoices-table .invoices-table--row a.button {
            line-height: 8.192px    ; } }
        @media screen and (min-width: 1920px) {
          .inner-container--invoices .invoices-table .invoices-table--header a.button,
          .inner-container--invoices .invoices-table .invoices-table--row a.button {
            letter-spacing: 4px    ; } }
        @media screen and (max-width: 1024px) {
          .inner-container--invoices .invoices-table .invoices-table--header a.button,
          .inner-container--invoices .invoices-table .invoices-table--row a.button {
            letter-spacing: 2.13333px    ; } }
        @media screen and (max-width: 1024px) {
          .inner-container--invoices .invoices-table .invoices-table--header a.button span,
          .inner-container--invoices .invoices-table .invoices-table--row a.button span {
            display: none    ; } }
        .inner-container--invoices .invoices-table .invoices-table--header a.button img,
        .inner-container--invoices .invoices-table .invoices-table--row a.button img {
          height: 14px;
          width: auto;
          display: none; }
          @media screen and (max-width: 1024px) {
            .inner-container--invoices .invoices-table .invoices-table--header a.button img,
            .inner-container--invoices .invoices-table .invoices-table--row a.button img {
              display: inline-block    ; } }
    .inner-container--invoices .invoices-table .invoices-table--header {
      font-weight: 700;
      color: #1D1D1B; }
  .inner-container--invoices .invoice-pagination {
    display: flex;
    margin: 1.66667vw    ;
    justify-content: center; }
    @media screen and (min-width: 1920px) {
      .inner-container--invoices .invoice-pagination {
        margin: 32px    ; } }
    @media screen and (max-width: 1024px) {
      .inner-container--invoices .invoice-pagination {
        margin: 17.06667px    ; } }
    .inner-container--invoices .invoice-pagination .pagination-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.66667vw    ;
      height: 1.66667vw    ;
      margin: 0vw 0.41667vw   ;
      cursor: pointer;
      color: #1D1D1B;
      border-bottom-width: 0.08333vw    ;
      border-bottom-style: solid;
      border-bottom-color: white; }
      @media screen and (min-width: 1920px) {
        .inner-container--invoices .invoice-pagination .pagination-item {
          width: 32px    ; } }
      @media screen and (max-width: 1024px) {
        .inner-container--invoices .invoice-pagination .pagination-item {
          width: 17.06667px    ; } }
      @media screen and (min-width: 1920px) {
        .inner-container--invoices .invoice-pagination .pagination-item {
          height: 32px    ; } }
      @media screen and (max-width: 1024px) {
        .inner-container--invoices .invoice-pagination .pagination-item {
          height: 17.06667px    ; } }
      @media screen and (min-width: 1920px) {
        .inner-container--invoices .invoice-pagination .pagination-item {
          margin: 0px 8px   ; } }
      @media screen and (max-width: 1024px) {
        .inner-container--invoices .invoice-pagination .pagination-item {
          margin: 0px 4.26667px   ; } }
      @media screen and (min-width: 1920px) {
        .inner-container--invoices .invoice-pagination .pagination-item {
          border-bottom-width: 1.6px    ; } }
      @media screen and (max-width: 1024px) {
        .inner-container--invoices .invoice-pagination .pagination-item {
          border-bottom-width: 0.85333px    ; } }
      .inner-container--invoices .invoice-pagination .pagination-item:hover, .inner-container--invoices .invoice-pagination .pagination-item.pagination-item--active {
        border-bottom-color: #1D1D1B; }
      .inner-container--invoices .invoice-pagination .pagination-item.pagination-item--active {
        font-weight: bold; }

/**
 * Two-factor authentication
 */
.inner-container--twofa {
  width: 39.16667vw    ; }
  @media screen and (min-width: 1920px) {
    .inner-container--twofa {
      width: 752px    ; } }
  @media screen and (max-width: 1024px) {
    .inner-container--twofa {
      width: 401.06667px    ; } }
  .inner-container--twofa h2 {
    margin: 1.66667vw 0vw   ;
    width: 100%; }
    @media screen and (min-width: 1920px) {
      .inner-container--twofa h2 {
        margin: 32px 0px   ; } }
    @media screen and (max-width: 1024px) {
      .inner-container--twofa h2 {
        margin: 17.06667px 0px   ; } }
  .inner-container--twofa form {
    margin-top: 0vw    ; }
    @media screen and (min-width: 1920px) {
      .inner-container--twofa form {
        margin-top: 0px    ; } }
    @media screen and (max-width: 1024px) {
      .inner-container--twofa form {
        margin-top: 0px    ; } }
    .inner-container--twofa form > label:nth-of-type(2n) {
      padding-left: 0; }
  .inner-container--twofa p {
    margin-bottom: 1.66667vw    ; }
    @media screen and (min-width: 1920px) {
      .inner-container--twofa p {
        margin-bottom: 32px    ; } }
    @media screen and (max-width: 1024px) {
      .inner-container--twofa p {
        margin-bottom: 17.06667px    ; } }
  .inner-container--twofa img {
    display: block; }
  .inner-container--twofa h3 {
    font-size: 1.33333vw    ;
    letter-spacing: 0.03333vw    ;
    line-height: 2.08333vw    ;
    margin: 1.66667vw 0vw 0.83333vw 0vw ;
    font-weight: 600; }
    @media screen and (min-width: 1920px) {
      .inner-container--twofa h3 {
        font-size: 25.6px    ; } }
    @media screen and (max-width: 1024px) {
      .inner-container--twofa h3 {
        font-size: 13.65333px    ; } }
    @media screen and (min-width: 1920px) {
      .inner-container--twofa h3 {
        letter-spacing: 0.64px    ; } }
    @media screen and (max-width: 1024px) {
      .inner-container--twofa h3 {
        letter-spacing: 0.34133px    ; } }
    @media screen and (min-width: 1920px) {
      .inner-container--twofa h3 {
        line-height: 40px    ; } }
    @media screen and (max-width: 1024px) {
      .inner-container--twofa h3 {
        line-height: 21.33333px    ; } }
    @media screen and (min-width: 1920px) {
      .inner-container--twofa h3 {
        margin: 32px 0px 16px 0px ; } }
    @media screen and (max-width: 1024px) {
      .inner-container--twofa h3 {
        margin: 17.06667px 0px 8.53333px 0px ; } }

/**
 * Download
 */
.inner-container--download {
  width: 39.16667vw    ; }
  @media screen and (min-width: 1920px) {
    .inner-container--download {
      width: 752px    ; } }
  @media screen and (max-width: 1024px) {
    .inner-container--download {
      width: 401.06667px    ; } }
  .inner-container--download > h1 {
    width: 100%; }
  .inner-container--download > p {
    width: 100%; }
  .inner-container--download .downloads {
    display: flex;
    width: 100%; }
    .inner-container--download .downloads > div {
      flex: 1 1 33%;
      padding-right: 1.66667vw    ; }
      @media screen and (min-width: 1920px) {
        .inner-container--download .downloads > div {
          padding-right: 32px    ; } }
      @media screen and (max-width: 1024px) {
        .inner-container--download .downloads > div {
          padding-right: 17.06667px    ; } }
      .inner-container--download .downloads > div:hover.grey img {
        filter: saturate(1); }
      .inner-container--download .downloads > div img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1.66667vw    ;
        margin-top: 3.33333vw    ;
        height: 6.66667vw    ; }
        @media screen and (min-width: 1920px) {
          .inner-container--download .downloads > div img {
            margin-bottom: 32px    ; } }
        @media screen and (max-width: 1024px) {
          .inner-container--download .downloads > div img {
            margin-bottom: 17.06667px    ; } }
        @media screen and (min-width: 1920px) {
          .inner-container--download .downloads > div img {
            margin-top: 64px    ; } }
        @media screen and (max-width: 1024px) {
          .inner-container--download .downloads > div img {
            margin-top: 34.13333px    ; } }
        @media screen and (min-width: 1920px) {
          .inner-container--download .downloads > div img {
            height: 128px    ; } }
        @media screen and (max-width: 1024px) {
          .inner-container--download .downloads > div img {
            height: 68.26667px    ; } }
      .inner-container--download .downloads > div.grey img {
        filter: saturate(0);
        transition: filter 300ms ease; }
    .inner-container--download .downloads .download-button {
      width: 100%; }

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0; }

/**
 * Datepicker colors
 */
.datepicker--day-name {
  color: #1D1D1B;
  font-weight: 700; }

.datepicker--cell.-range-to- {
  border: 1px solid rgba(39, 55, 230, 0.5);
  background-color: rgba(39, 55, 230, 0.1); }

.datepicker--cell.-selected-,
.datepicker--cell.-selected-.-current-,
.datepicker--cell.-selected-.-focus-,
.-selected-.datepicker--cell-day.-other-month-,
.-selected-.datepicker--cell-year.-other-decade- {
  background: #2737E6; }

.datepicker--cell.-range-from- {
  border: 1px solid rgba(39, 55, 230, 0.5); }

.datepicker--cell.-in-range- {
  background: rgba(39, 55, 230, 0.1); }

.datepicker--cell.-current-.-in-range-,
.datepicker--cell.-current- {
  color: #2737E6; }

.datepicker--cell.-in-range-.-focus- {
  background: rgba(39, 55, 230, 0.2); }

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(39, 55, 230, 0.1); }
