/**
 * Fonts file, please import in other stylesheets
 **/
@include font('DINPro', DINPro-Black, normal, 900);
@include font('DINPro', DINPro-Bold, normal, 700);
@include font('DINPro', DINPro-Regular, normal, 400);
@include font('DINPro', DINPro-Light, normal, 300);
@include font('Rajdhani', Rajdhani-Bold, normal, 700);
@include font('Rajdhani', Rajdhani-SemiBold, normal, 600);
@include font('Rajdhani', Rajdhani-Medium, normal, 500);
@include font('Rajdhani', Rajdhani-Regular, normal, 400);
@include font('Rajdhani', Rajdhani-Light, normal, 300);