/** 
* Variable file, please import in other stylesheets
**/

// Colors
$mainColor: #2737E6;
$secondColor: #1D1D1B;
$thirdColor: #FFB55C;
$fourthColor: #43bd51;
$headingColor: #EAEAE8;
$subColor: #FF4D5A;
$bodyColor: #707070;
$lineColor: #dcdcdc;

//breakpoints
$laptop: 1440px;
$tablet: 1024px;
$mobile: 640px;

// Fonts
$headingFont: 'DINPro', sans-serif;
$bodyFont: 'Rajdhani', sans-serif;

//responsive
$designWidth: 1200;
$scaleMax: 1920;
$scaleMin: 1024;

//VARious
$containerPadding: 15px;

