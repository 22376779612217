/**
 * Subscriptions
 */

.inner-container--subscriptions
{
    @include fp(width, 470);
    p
    {
        @include fp(margin-bottom, 40);
    }
    section
    {
        @include fp(margin-bottom, 50);
    }
    form
    {
        width: 100%;
    }
    .duration
    {
        display: block;
        @include fp(margin-bottom, 15);
        cursor: pointer;
        input[type="radio"]
        {
            display: none;
        }
        .inner
        {
            display: flex;
            width: 95%;
            @include fp(height, 50);
            color: white;
            transform-origin: left center;
            transition: color 200ms ease, width 200ms ease;
            .title
            {
                display: flex;
                flex-wrap: wrap;
                @include fp(flex-basis, 85);
                flex-grow: 0;
                flex-shrink: 0;
                justify-content: center;
                align-items: center;
                align-content: center;
                @include fp(font-size, 14);
                background-color: #1E1E1E;
                transition: background-color 200ms ease;
                span.price
                {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    font-weight: 900;
                }
            }
            .content
            {
                display: flex;
                flex-grow: 1;
                flex-shrink: 1;
                position: relative;
                background-color: black;
                align-items: center;
                @include fp(font-size, 10);
                @include lock_scale($laptop, font-size, 10);
                @include fp(padding-left, 20);
                @include fp(padding-right, 20);
                transition: background-color 200ms ease, padding-right 200ms ease;
            }
        }
        input[type="radio"]:checked+.inner
        {
            width: 100%;
            .title
            {
                background-color: #5260FF;
            }
            .content
            {
                background-color: #2737E6;
                @include fp(padding-right, 40);
                &::after
                {
                    content: '';
                    @include fp(width, 14);
                    @include fp(height, 11);
                    background-image: url('/dist/images/check.svg');
                    background-size: contain;
                    background-position: center;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto 0;
                    @include fp(right, 20);
                }
            }
        }
        
        input[type="radio"]:not(:checked)+.inner
        {
            &:hover
            {
                width: 97%;
            }
        }
    }
    .check
    {
        color: $secondColor;
        input
        {
            vertical-align: middle;
            @include fp(margin-right, 5);
        }
    }
}