/**
 * Invoices
 */

.inner-container--invoices
{
    @include fp(width, 470);
    &>h1
    {
        width: 100%;
    }
    &>p
    {
        width: 100%;
    }

    .date-picker
    {
        border: 1px solid $lineColor;
        @include fp(width, 130);
        @include lock_scale($laptop, width, 130);
        position: relative;
        @include fp(margin-top, 15);
        @include fp(margin-right, 15);
        @include fp(margin-bottom, 10);
        &::before
        {
            content: '';
            display: block;
            width: 1px;
            height: 60%;
            position: absolute;
            background-color: $lineColor;
            @include fp(left, 55);
            @include lock_scale($laptop, left, 55);
            top: 0;
            bottom: 0;
            margin: auto;
        }
        &::after
        {
            content: '';
            background-image: url('/dist/images/calendar.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            display: block;
            width: 15px;
            height: 15px;
            position: absolute;
            top: 0;
            bottom: 0;
            @include fp(right, 7); 
            @include lock_scale($laptop, right, 7);
            margin: auto;
        }
        input
        {
            @include fp(width, 110);
            @include lock_scale($laptop, width, 110);
            outline: 0;
            border: 0;
            @include fp(height, 25);
            @include lock_scale($laptop, height, 25);
            font-family: $headingFont;
            @include fp(font-size, 7.5);
            @include lock_scale($laptop, font-size, 7.5);
            @include fp(line-height, 9);
            @include lock_scale($laptop, line-height, 9);
            @include fp(letter-spacing, 0.33);
            @include lock_scale($laptop, letter-spacing, 0.33);
            text-align: center;
        }
    }
    .dropdown
    {
        border: 1px solid $lineColor;
        @include fp(width, 130);
        @include lock_scale($laptop, width, 130);
        position: relative;
        @include fp(margin-top, 15);
        @include fp(margin-right, 15);
        @include fp(margin-bottom, 10);
        &::after
        {
            content: '';
            width: 0;
            height: 0;
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            @include fp(right, 5);
            border-style: solid;
            border-color: $secondColor transparent transparent transparent;
            margin: auto;
            @include fp(border-width, 5 5 0 5);
        }
        select
        {
            appearance: none;
            border: 0;
            outline: 0;
            width: 100%;
            @include fp(height, 25);
            @include lock_scale($laptop, height, 25);
            @include fp(padding-left, 15);
            @include fp(padding-right, 15);
        }
    }

    .invoices-table
    {
        display: flex;
        flex-direction: column;
        width: 100%;
        @include fp(margin-top, 40);
        .invoices-table--header,
        .invoices-table--row
        {
            display: flex;
            align-items: center;
            @include fp(height, 30);
            @include lock_scale($laptop, height, 30);
            border-bottom: 1px solid $lineColor;
            font-weight: 500;
            @include fp(font-size, 10);
            @include lock_scale($laptop, font-size, 10);

            & > div:nth-child(1)
            {
                flex: 1 5 21%;
                color: $secondColor;
            }
            & > div:nth-child(2)
            {
                flex: 1 5 21%;
            }
            & > div:nth-child(3)
            {
                flex: 1 5 21%;
            }
            & > div:nth-child(4)
            {
                flex: 1 5 21%;
            }
            & > div:nth-child(5)
            {
                flex: 1 1 16%;
                text-align: center;
                overflow: hidden;
                @include media($laptop, text-align, left);
            }
            a.button
            {
                display: flex;
                align-items: center;
                justify-content: center;
                @include fp(height, 17);
                @include lock_scale($laptop, height, 17);
                text-align: center;
                width: 100%;
                padding: 0;
                font-weight: 700;
                @include fp(font-size, 8);
                @include fp(line-height, 9.6);
                @include fp(letter-spacing, 2.5);
                span
                {
                    @include media($tablet, display, none);
                }
                img
                {
                    height: 14px;
                    width: auto;
                    display: none;
                    @include media($tablet, display, inline-block);
                }
            }
        }
        .invoices-table--header
        {
            font-weight: 700;
            color: $secondColor;
        }
    }

    .invoice-pagination
    {
        display: flex;
        @include fp(margin, 20);
        justify-content: center;
        .pagination-item
        {
            display: flex;
            align-items: center;
            justify-content: center;
            @include fp(width, 20);
            @include fp(height, 20);
            @include fp(margin, 0, 5);
            cursor: pointer;
            color: $secondColor;
            @include fp(border-bottom-width, 1);
            border-bottom-style: solid;
            border-bottom-color: white;
            &:hover,
            &.pagination-item--active
            {
                border-bottom-color: $secondColor;
            }
            &.pagination-item--active
            {
                font-weight: bold;
            }
        }
    }
}