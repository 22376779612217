/**
 * Elements file, please import in other stylesheets
 **/



.container
{
	max-width: 100vw;
	overflow: hidden;
	padding: 0 $containerPadding;
	margin: auto;
	min-height: 100vh;
	position: relative;
}

.logo
{
	display: block;
	position: fixed;
	@include fp(top, 50);
	@include media($mobile, top, $containerPadding);
	@include fp(left, 83);
	@include media($mobile, left, $containerPadding);
	@include fp(width, 60);
	@include fp(height, 55);
	z-index: 11;
    transition: top 300ms ease, width 300ms ease, height 300ms ease;
	img
	{
		width: 100%;
		height: 100%;
	}
	&.sticky
	{
		@include fp(top, 10);
		@include media($mobile, top, $containerPadding);
		@include fp(width, 45);
		@include media($mobile, width, scale_static_min(60));
		@include fp(height, 40);
		@include media($mobile, height, scale_static_min(55));
	}
}

.watermark
{
   @include fp(width, 450);
   @include fp(height, 450);
   position: absolute;
   @include fp(bottom, 20);
   @include fp(right, 0);
   transform: translateX(25%);
   z-index: -1;
}


.inner-container
{
    @include fp(padding, 100, 0);
    @include fp(width, 600);
    max-width: 100%;
    margin: auto;
    min-height: 100vh;
    display: flex;
	align-items: center;
	flex-wrap: wrap;
	align-content: center;
    h1
    {
        @include fp(margin-bottom, 10);
    }
}

.popup-trigger
{
	position: relative;
	cursor: pointer;
	.popup
	{
		display: none;
		background-color: $mainColor;
		font-family: $headingFont;
		@include fp(font-size, 14);
		@include fp(line-height, 16.8);
		@include fp(letter-spacing, 1.4);
		@include fp(bottom, -50);
		@include fp(padding, 5, 14, 7, 14);
		left: 50%;
		transform: translateX(-50%);
		color: white;
		position: absolute;
		&::before
		{
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			margin: auto;
			border-style: solid;
			border-color: transparent transparent $mainColor transparent;
			@include fp(border-width, 5);
			width: 0;
			height: 0;
			transform: translateY(-100%);
		}
	}
	&:hover
	{
		& .popup
		{
			display: block;
		}
	}
}

.time-left-container
{
	display: block;
	font-family: $headingFont;
	background-color: #1D1D1B;
	position: fixed;
	@include fp(font-size, 14);
	@include fp(line-height, 16.8);
	@include fp(padding, 5);
	@include fp(right, 30);
	@include fp(bottom, 20);
	color: white;
	font-weight: 300;
	z-index: 5;
	a
	{
		color: white;
	}

	&.time-left-container--green
	{
		background-color: $fourthColor;
	}
	
	&.time-left-container--orange
	{
		background-color: $thirdColor;
	}

	&.time-left-container--red
	{
		background-color: $subColor;
	}
}

.button
{
	display: inline-block;
	appearance: none;
	cursor: pointer;
    border: 0;
    outline: 0;
	background-color: $mainColor;
	font-family: $headingFont;
	color: white;
	@include fp(font-size, 14);
	@include fp(letter-spacing, 1.4);
	@include fp(line-height, 16.8);
	@include fp(padding, 8, 38);
	transition: background-color 100ms ease;
	white-space: nowrap;
	&.button--compact
	{
		@include fp(padding, 8, 15);
	}
	&:hover
	{
		background-color: #212a8a;
	}
}

.success-message
{
	display: none;
	width: 100%;
	@include fp(padding, 10, 20);
	border: 1px solid green;
	color: green;
	border-radius: 5px;
	@include fp(margin-bottom, 32);
}

.success-message--visible
{
	display: block;
}

.error-message
{
	display: none;
	width: 100%;
	@include fp(padding, 10, 20);
	border: 1px solid red;
	color: red;
	border-radius: 5px;
	@include fp(margin-bottom, 32);
}

.error-message--visible
{
	display: block;
}

.download-button
{
	display: flex;
	@include fp(height, 40);
	cursor: pointer;
	&:hover
	{
		.text::before
		{
			width: 100%;
		}
	}
	.icon
	{
		height: 100%;
		flex: 0 0 25%;
		display: flex;
		align-items: center;
		background-color: $secondColor;
		align-self: center;
		justify-content: center;
		svg
		{
			width: 40%;
			height: auto;
		}
	}
	.text
	{
		position: relative;
		display: flex;
		align-items: center;
		align-content: center;
		flex-wrap: wrap;
		flex: 1 1 75%;
		color: white;
		background-color: black;
		@include fp(padding-left, 10);
		@include fp(font-size, 12);
		&::before
		{
			content: '';
			display: flex;
			width: 0px;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			margin: auto 0;
			background-color: $secondColor;
			z-index: 1;
			transition: width 300ms ease;
		}
		.inner
		{
			z-index: 2;
		}
		.under-text
		{
			width: 100%;
			@include fp(font-size, 10);
			display: block;
			color: darken(white, 25%);
		}
	}
}