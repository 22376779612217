/**
 * Two-factor authentication
 */
 .inner-container--twofa
{
    @include fp(width, 470);
    h2
    {
        @include fp(margin, 20, 0);
        width: 100%;
    }

    form 
    {
        @include fp(margin-top, 0);
        & > label:nth-of-type(2n)
        {
            padding-left: 0;
        }
    }
    
    p
    {
        @include fp(margin-bottom, 20);
    }

    img
    {
        display: block;
    }

    h3
    {
        @include fp(font-size, 16);
        @include fp(letter-spacing, 0.4);
        @include fp(line-height, 25);
        @include fp(margin, 20, 0, 10, 0);
        font-weight: 600;
    }
}