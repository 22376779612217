/**
 * Functions file, please import in other stylesheets
 **/

@function scale_dynamic($size)
{
	@if type-of($size) == number {
		$vw: ($size / $designWidth)*100;
		@return #{$vw}vw;
	}
	@return unquote("");
}

@function scale_static_min($size)
{
	@if type-of($size) == number {
		$px: ($size / $designWidth)*$scaleMin;
		@return #{$px}px;
	}
	@return unquote("");
}

@function scale_static_max($size)
{	
	@if type-of($size) == number {
		$px: ($size / $designWidth)*$scaleMax;
		@return #{$px}px;
	}
	@return unquote("");
}

@function scale_static_custom($size, $width)
{	
	@if type-of($size) == number {
		$px: ($size / $designWidth)*$width;
		@return #{$px};
	}
	@return unquote("");
}

@mixin lock_scale($size, $prop, $value)
{
	@include media($size, $prop, scale_static_custom($value, $size));
}

@mixin fp($prop, $value, $value2: "", $value3: "", $value4: "", $value5: unquote(""))
{
	#{$prop}: scale_dynamic($value) scale_dynamic($value2) scale_dynamic($value3) scale_dynamic($value4) #{$value5};
	@media screen and (min-width: #{$scaleMax}px)
	{
		#{$prop}: scale_static_max($value) scale_static_max($value2) scale_static_max($value3) scale_static_max($value4) #{$value5};
	}
	@media screen and (max-width: #{$scaleMin}px)
	{
		#{$prop}: scale_static_min($value) scale_static_min($value2) scale_static_min($value3) scale_static_min($value4) #{$value5};
	}
}

@mixin media($size, $prop, $value, $value2: unquote(""), $value3: unquote(""), $value4: unquote(""), $value5: unquote(""))
{
	@media screen and (max-width: #{$size})
	{
		#{$prop}: #{$value} #{$value2} #{$value3} #{$value4} #{$value5};
	}
}

@mixin font($name, $path, $style, $weight)
{
	 @font-face {
		font-family: #{$name};
		font-style: #{$style};
		font-weight: #{$weight};
		src: url('../webfonts/#{$path}.woff2') format('woff2'), 
		url('../webfonts/#{$path}.woff') format('woff');
	}
}

%appearance-none
{
	outline: none;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

%text-overflow
{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}