/**
 * Typography file, please import in other stylesheets
 **/

body
{
	font-family: $bodyFont;
	color: $bodyColor;
}

a
{
	color: $bodyColor;
	text-decoration: none;
}

p
{
	@include fp(font-size, 16);
	@include fp(letter-spacing, 0.3);
	@include fp(line-height, 19.2);
	font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6
{
	font-family: $headingFont;
    color: $headingColor;
}

h1
{
	@include fp(font-size, 50);
	@include fp(letter-spacing, 0.4);
	@include fp(line-height, 60);
	font-weight: 400;
}

h2
{
	@include fp(font-size, 25);
	@include fp(letter-spacing, 0.4);
	@include fp(line-height, 40);
	font-weight: 400;
}

h3
{
	@include fp(font-size, 18);
	@include fp(letter-spacing, 1.1);
	@include fp(line-height, 21.6);
}

h4
{

}

h5
{

}

h6
{

}
