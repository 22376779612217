/**
 * Global
 */

@import '_vars';
@import '_functions';
@import '_fonts';
@import '_grid';
@import '_typography';
@import '_utils';

@import 'elements/_elements';
@import 'elements/_menu';
@import 'elements/_bottom-bar';

@import 'pages/_login.scss';
@import 'pages/_overview';
@import 'pages/_settings';
@import 'pages/_subscriptions';
@import 'pages/_invoices';
@import 'pages/_twofa';
@import 'pages/download';

*
{
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

/**
 * Datepicker colors
 */
.datepicker--day-name
{
	color: $secondColor;
	font-weight: 700;
}
.datepicker--cell.-range-to- {
    border: 1px solid rgba($mainColor,.5);
    background-color: rgba($mainColor,.1);
}
.datepicker--cell.-selected-,
.datepicker--cell.-selected-.-current-,
.datepicker--cell.-selected-.-focus-,
.-selected-.datepicker--cell-day.-other-month-,
.-selected-.datepicker--cell-year.-other-decade-
{
	background: $mainColor;
}
.datepicker--cell.-range-from-
{
    border: 1px solid rgba($mainColor,.5);
}
.datepicker--cell.-in-range-
{
	background: rgba($mainColor,.1);
}
.datepicker--cell.-current-.-in-range-,
.datepicker--cell.-current-
{
	color: $mainColor;
}
.datepicker--cell.-in-range-.-focus-
{
	background: rgba($mainColor,.2);
}
.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade-
{
	background-color: rgba($mainColor,.1);
}