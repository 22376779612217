/**
 * Settings
 */
.inner-container--settings
{
    @include fp(width, 470);
    form
    {
        @include fp(margin-top, 35); 
        & > label
        {
            font-weight: 900;
            @include fp(font-size, 10);
            @include fp(line-height, 12);
            @include fp(letter-spacing, 0.5);

            @include fp(margin-bottom, 25);
            &:not(.col-12):nth-of-type(2n-1)
            {
                @include fp(padding-right, 37.5);
                @include media($mobile, padding-right, 0);
            }
            
            &:nth-of-type(2n)
            {
                @include fp(padding-left, 37.5);
                @include media($mobile, padding-left, 0);
            }
            input:not([type="checkbox"]):not([type="submit"]):not([type="radio"]),
            select
            {
                @extend %appearance-none;
                color: #4F5362;
                width: 100%;
                background-color: transparent;
                @include fp(height, 35);
                font-weight: 300;
                @include fp(font-size, 16);
                @include fp(letter-spacing, 0.3);
                @include fp(line-height, 19.2);
                border: 0;
                border-bottom: 1px solid $lineColor;
                @include fp(margin-bottom, 10);
                &::placeholder
                {
                    color: #4f536270;
                }
            }

            input:disabled
            {
                background-color: #dcdcdc47 !important;
                cursor: not-allowed;
            }

            .select-wrapper
            {
                position: relative;
                &::after,
                &::before
                {
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    @include fp(border-width, 3, 1.5);
                    position: absolute;
                    @include fp(right, 5);
                    top: 0;
                    bottom: 0; 
                    margin: auto;
                }
                &::after
                {
                    border-color: $bodyColor transparent transparent transparent;
                    transform: translateY(30%);
                }
                &::before
                {
                    border-color: transparent transparent $bodyColor transparent;
                    transform: translateY(-130%);
                }
            }

            input[type="checkbox"]{
                vertical-align: middle;
                @include fp(margin-right, 5);
            }
        }
    }
}