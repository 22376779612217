/**
 * Menu
 */

 footer
 {
     background-color: $secondColor;
     color: white;
     @include fp(min-height, 200);
     @include fp(padding-top, 40);
     @include fp(padding-left, 110);
     @include fp(padding-right, 110);
     @include media($mobile, padding-left, $containerPadding);
     @include media($mobile, padding-right, $containerPadding);
     & > div
     {
        @include media($tablet, margin-bottom, 40px);
     }
     h3
     {
         @include fp(margin-bottom, 20);
     }
     p
     {
         color: white;
         a
         {
             color: white;
         }
     }
 }